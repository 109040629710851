const DESKTOP_WIDTH = '(min-width: 1329px)'
const TABLET_WIDTH = '(min-width: 900px) and (max-width: 1249px)'
const MOBILE_WIDTH = '(max-width: 900px)'
const MOBILE_TABLET = '(max-width: 1249px)'
const MAX_PAGE_WIDTH = '1366px;'
const MAX_WIDTH = 1366
const PATIENT_ID_ROLES = 1
const DOCTOR_ID_ROLES = 2

export const UTMs = {
  organic_site_cannect_botao:
    '?utm_medium=organic&utm_source=site&utm_campaig[…]cannect_organic_geral&utm_content=cannect&utm_term=botao',
  areaparceiros_institucional_ativacao:
    '?utm_medium=organic&utm_source=site&utm_campaign=202410_areaparceiros_institucional_ativacao_conversao_continuo_site_cannect_organic_geral&utm_content=cannect&utm_term=menu'
}

export const FILTER_NAME_PRODUCTS = {
  brand_id: 'Marca',
  shape_id: 'Forma',
  class_id: 'Classe',
  subclass_id: 'subclass_id',
  country_id: 'country_id',
  supplier_id: 'Origem do produto',
  cannect_choice: 'cannect_choice',
  cbd_concentration: 'Concentração de CBD',
  thc_concentration: 'Concentração de THC',
  translated_name: 'translated_name',
  subcategory_id: 'subcategory_id',
  pathology: 'pathology',
  cannabidiol_id: 'Canabinoide',
  category_id: 'Categoria'
} as any

export const FILTER_NAME_PRODUCTS_CURATORSHIP = {
  brand_id: 'Marca',
  shape_id: 'Forma',
  class_id: 'Classe',
  subclass_id: 'subclass_id',
  country_id: 'country_id',
  supplier_id: 'Origem do produto',
  cannect_choice: 'cannect_choice',
  cbd_concentration: 'Concentração de CBD',
  thc_concentration: 'Concentração de THC',
  translated_name: 'translated_name',
  subcategory_id: 'subcategory_id',
  pathology: 'pathology',
  cannabidiol_id: 'Canabinoide',
  category_id: 'Categoria',
  veiculo: 'Veiculo',
  extraction_method: 'Método de Extração',
  gmp: 'GMP'
} as any

export const EMISSAO = '18%2F11%2F2022'
export const IDENTIDADE = 'IDENTIDADE'
export const RESIDENCIAL = 'RESIDENCIAL'
export const ANVISA = 'ANVISA'
export const PRESCRICAO = 'PRESCRICAO'
export const VINCULO = 'VINCULO'

export type CREDIT_CARD = 'credit_card'
export type PIX = 'pix'
export type BOLETO = 'boleto'

export const STATUS_ORDER = [
  'created',
  'finished_items',
  'waiting_documentation',
  'waiting_anvisa',
  'waiting_approval',
  'awaiting_payment',
  'paid',
  'shipping_supplier',
  'dispatched',
  'anvisa_br',
  'delay_release',
  'release_anvisa',
  'delivered',
  'approved_documents',
  'waiting_payment',
  'disapproved_document',
  'payment_refused',
  'waiting_appointment',
  'follow_up',
  'generated_order',
  'didnt_generate_order',
  'canceled',
  'opportunities',
  'waiting_trace_generation',
  'lost'
]

export const GENDER_LIST = [
  { label: 'Masculino', value: 'M' },
  { label: 'Feminino', value: 'F' },
  { label: 'Prefiro não informar', value: 'NF' }
]

export const STATE_LIST = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]
export {
  PATIENT_ID_ROLES,
  DOCTOR_ID_ROLES,
  DESKTOP_WIDTH,
  TABLET_WIDTH,
  MOBILE_WIDTH,
  MAX_PAGE_WIDTH,
  MAX_WIDTH,
  MOBILE_TABLET
}
