import { gene02, productsFaq, professionalFaq, treatments02 } from 'assets/img'
import { FirstAidKit, UsersThree } from 'phosphor-react'

const AccordionPatientData = [
  {
    title: 'É permitido o uso de cannabis no Brasil?',
    text: `Sim! A cannabis é legal no Brasil através de duas resoluções vigentes da  Anvisa (Agência Nacional de Vigilância Sanitária). A RDC (Resolução de Diretoria Colegiada) 660 foi aprovada em 2015 e permitiu a importação de cannabis. Em 2019, a agência também aprovou a RDC 327, que permitiu a venda de cannabis na farmácia.
    Por isso, é permitido comprar canabidiol no Brasil.
    `
  },
  {
    title: 'Como conseguir a autorização da Anvisa?',
    text: `Para importar produtos de cannabis é necessário ter uma receita médica de um profissional habilitado <a href="https://www.cannect.life/agendamento" target="_blank">E nós também podemos te ajudar com isso.</a>.
    <br/>
    <br/>
    Com a receita em mãos, basta entrar no do link do Governo Federal <a href="https://www.gov.br/pt-br/servicos/solicitar-autorizacao-para-importacao-excepcional-de-produtos-a-base-de-canabidiol" target="_blank">"Solicitar autorização para importar produtos de Cannabis"</a>
    e clicar em “iniciar” para fazer o pedido. É importante lembrar que a autorização pode ser usada durante dois anos.
    <br/>
    <br/>
    Caso queira saber o passo a passo para emitir a sua autorização dentro do site da Anvisa,
    <a href="https://www.cannect.life/blog/undefined/como-conseguir-autorizacao-para-importar-produtos-a-base-de-cannabis" target="_blank">clique aqui.</a></b>.
    `
  },
  {
    title: 'Como viajar com os produtos de cannabis?',
    text: `
    Para viajar dentro do território nacional, o paciente deve carregar consigo a prescrição correspondente aos produtos de cannabis que está levando, além da autorização de importação da Anvisa, em caso de produtos importados. É importante lembrar que, no caso de viagens ao exterior, vale a legislação vigente no país de destino sobre a cannabis.
    `
  },
  {
    title: 'O que é comprovante de vínculo?',
    text: `
    É possível efetuar a compra dos produtos para dependentes familiares. Neste caso,
    o comprador deve apresentar um documento que comprove seu parentesco com o paciente que receberá o produto.
    (RG ou certidão de nascimento).
    `
  },
  {
    title: 'Preciso comprar todos os produtos da minha receita de cannabis?',
    text: `
    Não há necessidade de comprar todos os produtos, porém toda e qualquer alteração no seu tratamento com cannabis deve ser realizada com o profissional de saúde habilitado.
    `
  },
  {
    title: 'Qual o prazo de entrega dos meus produtos de cannabis?',
    text: `
    Por causa de possíveis intercorrências no processo logístico, trabalhamos com o <strong>prazo de 15 dias úteis</strong> em caso de <strong>importação.</strong> Para produtos autorizados para a venda <strong>nas farmácias</strong>, o nosso prazo de entrega é <strong>72 horas.</strong>
    `
  },
  {
    title: 'Qual a validade de prescrição de cannabis?',
    text: `
    O tempo da receita de cannabis é de 6 (seis) meses. Ao final desse período, é necessário uma nova prescrição para poder comprar.
    `
  }
]
export const PatientDataFaq = {
  title: 'Para pacientes',
  accordionItems: AccordionPatientData,
  journeyItems: {
    title: 'Pacientes',
    icon: <UsersThree />,
    cardItems: [
      {
        id: 1,
        img: productsFaq,
        title: 'Cannect Produtos',
        link: 'https://www.cannect.life/produtos/1?translated_name='
      },
      { id: 2, img: gene02, title: 'Cannect Gene', link: 'https://www.cannect.life/teste-genetico' }
    ]
  }
}

const AccordionProfessionalsData = [
  {
    title: 'Como deve ser feita a prescrição de cannabis?',
    text: `Nós podemos te dar todo o suporte necessário para te ajudar no dia a dia
     <br/>
     <br/>
     Mas se você está interessado em um guia de prescrição de cannabis, a Cannect oferece cursos através da sua vertical de educação <a href="https://www.drcannabis.com.br/?utm_source=pagina-faq&utm_medium=cta&utm_id=cannect" target="_blank">Dr. Cannabis</a>, que podem ajudar a construir uma base sólida para entender a cannabis medicinal e a prescrever de forma prática.
    `
  },
  {
    title: 'Como escolher o melhor produto para cada paciente?',
    text: `Cada paciente tem uma necessidade de um produto ou combinação de produtos que
    terá o melhor resultado. Por exemplo, seu paciente pode se beneficiar de um produto rico em CBD,
     mas com uma pequena quantidade de THC, ou um outro produto de seja balanceado entre as quantidades de CBD e THC.
     <br/>
     <br/>
    Também existem nuances de combinações diferentes de CBD e THC administradas em horários diferentes do dia,
    de acordo com o estilo de vida e necessidade de cada paciente.
    <br/>
    <br/>
    Para explicar todas essas especificidades, a Cannect agora tem uma vertical de educação, que oferece <a href="https://www.cannect.life/educa"> cursos </a> que
    além de dar a base para entender a Cannabis medicinal, ensina a prescrever de forma prática e eficiente, dando todo suporte depois do curso.
    <br/>
    <br/>
    Nosso ecossistema ainda dispõe do <a href="https://www.cannect.life/cuida"> Cannect Cuida </a>, uma ferramenta de acolhimento de pacientes para o monitoramento de
    tratamento, com uma equipe de profissionais da saúde pronta para atender.`
  },
  {
    title: 'Como dosar e orientar a administração de Cannabis medicinal para meus pacientes?',
    text: `
    A prescrição segura de Cannabis medicinal envolve não apenas compreender suas propriedades,
    mas também seguir algoritmos e estudos. Para ajudar médicos a adquirir essas habilidades,
    a Cannect oferece um <a href="https://www.drcannabis.com.br/?utm_source=pagina-faq&utm_medium=cta&utm_id=cannect" target="_blank">curso
    que ensina a prescrição de forma prática e eficiente</a>, além de
    fornecer suporte especializado após a conclusão do curso.
      <br/>
      <br/>
    Além disso, a Cannect mantém uma
    <a href="https://api.whatsapp.com/send/?phone=5511950811547&text&type=phone_number&app_absent=0" target="_blank">comunidade ativa de médicos prescritores parceiros,</a>
    onde
    são discutidos casos clínicos e compartilhadas as melhores práticas. Essa comunidade é um espaço
    valioso para troca de experiências e atualização constante sobre a prescrição de Cannabis medicinal.
      `
  },
  {
    title: 'Como a Cannect me auxilia junto aos pacientes?',
    text: `
    A Cannect está pronta para te auxiliar e otimizar o fluxo de trabalho de profissionais
     da saúde na prática clínica diária através de diversas solução e principalmente, através
     do Cannect Assistente e do Cannect Cuida.

    Nosso <a href="https://www.cannect.life/assistente" target="_blank"> assistente de prescrição</a>, uma ferramenta gratuita, facilita o processo de envio de receitas
     digitais aos pacientes, ao enviá-las de forma automática via e-mail e SMS.

    O <a href="https://www.cannect.life/educa"> Cannect Cuida </a> é um serviço de cuidado coordenado, resguardado por um protocolo desenvolvido por nossa
    equipe médica, que acompanha o tratamento dos pacientes no cotidiano, tirando dúvidas, garantindo
    eficácia do tratamento e bem estar dos pacientes.

      `
  }
]

export const ProfessionalFaq = {
  title: 'Para profissionais',
  accordionItems: AccordionProfessionalsData,
  journeyItems: {
    title: 'Profissional da Saúde',
    icon: <FirstAidKit />,
    cardItems: [
      {
        id: 1,
        img: professionalFaq,
        title: 'Cannect Profissionais',
        link: 'https://www.cannect.life/profissionais-parceiros-cannabis-medicinal'
      },
      {
        id: 2,
        img: productsFaq,
        title: 'Cannect Produtos',
        link: 'https://www.cannect.life/produtos/1?translated_name='
      },
      { id: 3, img: treatments02, title: 'Cannect Assistente', link: 'https://www.cannect.life/assistente' },
      { id: 4, img: gene02, title: '', link: 'https://www.cannect.life/teste-genetico' }
    ]
  }
}
