import { gene02, productsFaq, professionalFaq, treatments02 } from 'assets/img'
import { FirstAidKit, UsersThree } from 'phosphor-react'

const AccordionPatientData = [
  {
    title: 'Cannabis é legal?',
    text: `Sim. No Brasil, a cannabis para usos medicinais é regularizada pela Anvisa (Agência Nacional de Vigilância Sanitária),
    através de duas RDC’s (Resolução de Diretoria Colegiada): a RDC 327, que estabelece requisitos para a comercialização,
    prescrição, a dispensação, o monitoramento e a fiscalização de produtos de Cannabis para fins medicinais;
    e a RDC 660, que trata especificamente de importação.
    `
  },
  {
    title: 'Como faço solicitação junto à Anvisa?',
    text: `Antes de tudo você vai precisar de uma prescrição de profissional habilitado, com informações do produto indicado,
    dosagem e via de administração. Não tem receita? <a href="https://www.cannect.life/agendamento" target="_blank"> Clique aqui para agendar uma consulta </a>.
    <br/>
    <br/>
    Já tem receita? Siga o passo a passo abaixo.
    <br/>
    <br/>
    1. Acesse o link "
    <a href="https://www.gov.br/pt-br/servicos/solicitar-autorizacao-para-importacao-excepcional-de-produtos-a-base-de-canabidiol" target="_blank">
    Solicitar autorização para importar produtos de Cannabis do Governo Federal"
    </a> e clique em <b>“Iniciar”</b>.
    <br/>
    <br/>
    2. Após fazer o login, confirme seus dados pessoais na página
    <br/>
    <b>Solicitação</b>. Após, clique em “Prosseguir”.
    <br/>
    <br/>
    3. Agora você deve escolher o <b>“Tipo de solicitação”</b>, ou seja, se é uma nova solicitação,
    a renovação de uma autorização já existente ou alguma alteração no documento.
    Para emitir pela primeira vez, clique em <b>"Inicial"</b>.
    <br/>
    <br/>
    <em>É importante lembrar que essa autorização vai valer por dois anos. Após o período, será preciso renovar a autorização.
    Se o uso do produto for para algum dependente, é necessário comprovar vínculo familiar.</em>
    <br/>
    <br/>
    4. Na opção <b>“Nome Comercial”</b>, preencha o nome do produto ou busque na lupa. Ao encontrar,
    clique em “adicionar os dados na tabela". Adicione quantos forem necessários, de acordo com sua receita.
    <br/>
    <br/>
    5. Consultando a própria receita, preencha os dados do prescritor, tais como:
    <br/>
    <br/>
    Nome do profissional habilitado;
    <br/>
    Especialidade;
    <br/>
    Município do prescritor;
    <br/>
    Número do conselho;
    <br/>
    Estado do prescritor;
    <br/>
    Telefone do prescritor;
    <br/>
    E-mail para contato.
    <br/>
    <br/>
    6. Em seguida, será necessário anexar a sua prescrição médica. Basta clicar em importar e selecionar o arquivo.
    <br/>
    <br/>
    Abaixo, há uma declaração que certifica que você tem a orientação correta sobre o tratamento com a cannabis medicinal.
    Clique em <b>"Concordo com a Declaração"</b>.
    <br/>
    <br/>
    7. Revise as informações, clique em <b>"Concordo com o Termo"</b> e depois <b>"Enviar a Solicitação"</b>.
    Novamente revise os seus dados e confirme na opção de <b>“Recebimento do Certificado”</b>. Assim,
    a sua solicitação já foi autorizada!
    <br/>
    <br/>
    Para ter mais informações sobre este processo, <a href="https://cannalize.com.br/autorizacao-de-importacao-de-produtos-de-cannabis-em-5-passos" target="_blank">
    esta reportagem da Cannalize pode ser útil
    </a>. Em caso de mais dúvidas, fique a vontade para procurar o atendimento ao cliente <a href="https://atendimento.cannect.life/atendimento-cannect" target="_blank">
    Cannect.
    </a>
    `
  },
  {
    title: 'Posso viajar com meus produtos?',
    text: `
    Para viagens dentro do território nacional,
    o paciente deve carregar consigo a prescrição correspondente aos produtos em sua posse, bem como a autorização de importação da Anvisa,
    em caso de produtos importados. É importante lembrar que, no caso de viagens ao exterior,
    vale a legislação vigente no país de destino.
    `
  },
  {
    title: 'O que é comprovante de vínculo?',
    text: `
    É possível efetuar a compra dos produtos para dependentes familiares. Neste caso,
    o comprador deve apresentar um documento que comprove seu parentesco com o paciente que receberá o produto.
    (RG ou certidão de nascimento).
    `
  },
  {
    title: 'Como posso conservar meus produtos?',
    text: `
    Cada produto tem uma maneira correta de conservação, normalmente divulgada em sua própria embalagem.
    Para ter mais certeza, consulte o atendimento.
    `
  },
  {
    title: 'Preciso comprar todos os produtos da minha receita?',
    text: `
    Não há necessidade de comprar todos os produtos, porém todo e qualquer alteração na dinâmica do seu tratamento,
    deve ser realizada com o profissional de saúde habilitado.
    `
  },
  {
    title: 'Qual o prazo de entrega? ',
    text: `
    No intuito de cobrir possíveis intercorrências no processo logístico,
    trabalhamos com o prazo de 15 dias úteis em caso de importação.
    Para produtos autorizados para a venda em território brasileiro, o prazo de entrega é 72 horas.
    `
  },
  {
    title: 'Qual a validade da prescrição?',
    text: `
    A prescrição médica tem validade de 6 (seis) meses e, ao final desse período,
    deve ser avaliada novamente por seu médico prescritor.
    `
  },
  {
    title: 'Quantos produtos eu posso comprar de cada vez? Preciso comprar toda a quantidade que está na receita?',
    text: `
    A quantidade de produtos a ser comprada depende do tratamento indicado para o seu caso.
    Não há necessidade de comprar todos os produtos de uma vez. Isso deve ser discutido com seu médico.
    `
  },
  {
    title: 'O produto chega direto na minha casa?',
    text: `
    Para produtos com venda autorizada em território brasileiro,
    a entrega é feita diretamente no endereço informado. No caso de produtos importados, há uma etapa intermediária,
    em que o produto passa por averiguação da Anvisa ao chegar no Brasil, antes de ser entregue em sua casa
    (sem custos adicionais).
    `
  },
  {
    title: ' Preciso fazer uma nova consulta para comprar novamente um medicamento que já comprei?',
    text: `
    Você pode comprar quantas vezes for necessário de acordo com a quantidade de itens informada na prescrição.
    `
  },
  {
    title: 'Como comprar na farmácia?',
    text: `
    Atualmente, é possível comprar produtos à base de cannabis na farmácia, desde que os produtos prescritos tenham venda autorizada no Brasil.
    Para isso, é necessário uma receita azul ou amarela que tem validade de até 1 mês.
    `
  },
  {
    title: 'Por que comprar com a Cannect?',
    text: `
    A Cannect é um facilitador do acesso ao tratamento com cannabis medicinal. Temos o marketplace mais completo do mercado,
    com mais de 800 itens para a sua saúde e seu bem estar. Mediamos sua interação com médicos das mais variadas especialidades.
    Atendemos dando suporte especializado para que os produtos cheguem em sua casa com agilidade.
    Promovemos acompanhamento de seu tratamento de maneira personalizada, com uma equipe de enfermeiros dedicados,
    de acordo com suas necessidades.
    `
  },
  {
    title: 'O status da minha compra é “faltando produto”. Por quê?',
    text: `
    Um produto pode constar no marketplace mas estar esgotado no hub. Isto acontece porque os itens são enviados ao hub pelos fornecedores sob demanda,
    não havendo estoque. Se isto aconteceu com seu pedido, fique tranquilo! Você receberá um email explicando os próximos passos.
    Importante: a entrega estará sujeita a atrasos, mas você receberá os produtos.
    `
  },
  {
    title: 'Como funciona o processo para iniciar o tratamento com cannabis medicinal?',
    text: `
    É importante esclarecer que o tratamento com cannabis é uma opção a ser considerada com base nas orientações de seu médico. Por isso,
    a consulta é o primeiro passo para saber se a cannabis é a recomendação adequada para seu caso. Ao sair da consulta,
    com a receita em mãos, entre em contato com nosso atendimento, para obter mais informações sobre promoções, descontos,
    novidades e esclarecimentos
    `
  },
  {
    title: 'Quanto custa o tratamento com cannabis medicinal? ',
    text: `
    Os custos de tratamento podem variar, em função de combinações como valores de consulta, preços de produtos, custos de custo de importação, entre outros.
    Nosso objetivo é democratizar o acesso à cannabis no Brasil, por isso, nossos preços são os mais acessíveis do mercado.
    `
  },
  {
    title: 'Quais são os tipos de produtos?',
    text: `
    Nosso marketplace possui mais de 800 produtos diferentes, mas os mais comuns são os óleos, os comestíveis e as pomadas. Desde julho de 2023,
    a Anvisa deixou de autorizar a importação de cannabis in natura (flores), então a venda destes produtos está suspensa até segunda ordem.
    `
  },
  {
    title: 'O tratamento com cannabis medicinal causa dependência?',
    text: `
    Alguns estudos apontam o THC (Tetrahidrocanabinol) como um canabinoide com potencial viciante, principalmente através do consumo fumado ou vaporizado.
    No entanto, a grande maioria dos produtos vendidos no marketplace da Cannect tem baixíssimo teor de THC, de acordo com a legislação de importação norte americana.
    Além disso,em julho de 2023 a Anvisa deixou de a importação de cannabis in natura (flores), então a venda destes produtos está suspensa até segunda ordem.
    `
  },
  {
    title: 'Onde vejo o status do meu produto?',
    text: `
    A maior parte dos pacotes importados pela Cannect são etiquetados pela embarcadora norte-americana FedEx, que faz viagens para o Brasil todos os dias.
    Um código de rastreio é gerado automaticamente quando o produto está pronto para envio. Assim, o paciente consegue acompanhar o status do produto a partir do momento que é registrado no site da FedEx.
    Para alinhar cada pedido, a Cannect envia um e-mail com a compra aprovada e o código de rastreio gerado (nesta mensagem é possível acessar o próprio link).
    `
  },
  {
    title: 'Como saber qual especialidade mais indicada para a minha patologia?',
    text: `
    O nosso time de acolhimento está pronto para dar suporte em caso de dúvidas sobre patologias e especialistas.
    Além disso, nosso blog traz novidades diariamente sobre os tratamentos com cannabis medicinal.
    `
  },
  {
    title: 'Posso medicar meu cachorro ou gato com meu óleo?',
    text: `
    Embora existam, em outros países, produtos à base de cannabis para uso veterinário,
    os produtos comercializados pela Cannect para importação e venda no território brasileiro são indicados para uso humano.
    `
  },
  {
    title: 'O que é plataforma do gov?',
    text: `
    A plataforma <a href="https://www.gov.br/pt-br" target="_blank"> GOV.BR </a> é o site oficial do Governo Federal,
    onde os cidadãos brasileiros podem fazer solicitação de documentos oficiais e serviços de órgãos públicos de âmbito federal,
    como a autorização de importação da Anvisa.
    `
  },
  {
    title: 'Por que meu pacote está parado em Campinas?',
    text: `
    Quando um produto à base de cannabis oriundo de importação entra no país ele deve, obrigatoriamente,
    passar por um processo de averiguação pela Anvisa (Agência Nacional de Vigilância Sanitária) no aeroporto de Viracopos,
    em Campinas/SP. Mas não se preocupe, depois de averiguado, o seu pedido será enviado no endereço informado sem custos adicionais.
    `
  },
  {
    title: 'Além do tratamento para insônia e saúde mental, o que mais a cannabis medicinal trata?',
    text: `
    Uma série de outras patologias, que tal <a href="https://www.cannect.life/agendamento" target="_blank"> agendar </a> uma consulta com um médico?
    `
  },
  {
    title: 'Qual o nível de THC permitido para importação?',
    text: `
    A legislação dos Estados Unidos permite a comercialização de produtos de até 0,3% de THC mas,
    existem produtos com maior concentração deste canabinoide para casos específicos, que devem ser adquiridos através de receituário específico e com autorização médica.
    `
  },
  {
    title: 'Tenho que andar com a minha prescrição? Qual a quantidade máxima?',
    text: `
    Sim, a quantidade máxima é estipulada pelo médico.
    `
  },
  {
    title: 'Qualquer médico pode prescrever?',
    text: `
    Sim
    `
  },
  {
    title: 'O Psicólogo pode prescrever?',
    text: `
    Segundo a Anvisa, órgão regulador da terapia canabinoide no Brasil,
    a prescrição está autorizada apenas a médicos que possuem CRM e cirurgiões-dentistas com CRO.
    `
  },
  {
    title: 'Posso compartilhar meus produtos?',
    text: `
    Não, é de uso pessoal e intransferível.
    `
  },
  {
    title: 'Posso dirigir após fazer uso do meu medicamento?',
    text: `
    Alguns produtos causam sonolência, então a operação de máquinas pesadas como automóveis não é recomendada.
    Consulte seu médico para obter informações mais precisas sobre os efeitos dos produtos
    `
  },
  {
    title: 'Tenho direito a estorno?',
    text: `
    A Anvisa considera os produtos à base de cannabis como medicamentos controlados, e estes produtos não estão inclusos no que se refere ao direito de arrependimento.
    Portanto, uma vez que a AWB foi gerada, não há possibilidade de estorno
    `
  },
  {
    title: 'Vocês tem consulta presencial?',
    text: `
    Alguns dos nossos médicos parceiros promovem atendimento presencial, mas através da Cannect, apenas o teleatendimento é possível.
    `
  },
  {
    title: 'Como é feito o agendamento online?',
    text: `
    Realizar seu agendamento online é muito simples. Siga o passo a passo abaixo:
    <br/>
    Acesse botão “Agende sua consulta” na Home
    <br/>
    Filtre sua escolha por Especialidade ou Sintoma
    <br/>
    Selecione a data de preferência para seu atendimento
    <br/>
    Escolha o Profissional Legalmente Habilitado (clique em “sobre” para detalhes de retorno, patologias que atende e minibio)
    <br/>
    Escolha um horário
    <br/>
    Faça o cadastro ou realize um login na plataforma
    <br/>
    Revise os dados do agendamento e realize o pagamento.
    `
  },
  {
    title: 'O que preciso ter em mãos para fazer um agendamento online?',
    text: `
    Não é preciso anexar documentos para realizar o agendamento, basta fazer um cadastro no site da cannect,
    usando um email acessível e seus dados pessoais.
    `
  },
  {
    title: 'Preciso pagar para agendar?',
    text: `
    O agendamento é feito mediante o pagamento antecipado da consulta, que pode ser feito via pix ou cartão de crédito.
    Antes de realizar o pagamento, você pode verificar as informações da consulta (tempo de duração,
    retorno incluso e especialidades atendidas pelo profissional legalmente habilitado)
    `
  },
  {
    title: 'Quem são os médicos listados?',
    text: `
    Nossa lista de profissionais legalmente habilitados está em constante atualização. Para sua comodidade,
    consulte a disponibilidade dos profissionais de acordo com sua data de preferência.
    `
  },
  {
    title: 'Preciso cancelar a consulta, como faço?',
    text: `
    Consulte nosso time de <a href="https://atendimento.cannect.life/atendimento-cannect" target="_blank"> acolhimento </a>
    `
  },
  {
    title: 'Como reagendar uma consulta?',
    text: `
    Consulte nosso time de <a href="https://atendimento.cannect.life/atendimento-cannect" target="_blank"> acolhimento </a>
    `
  }
]
export const PatientDataFaq = {
  title: 'Para pacientes',
  accordionItems: AccordionPatientData,
  journeyItems: {
    title: 'Pacientes',
    icon: <UsersThree />,
    cardItems: [
      {
        id: 1,
        img: productsFaq,
        title: 'Cannect Produtos',
        link: 'https://www.cannect.life/produtos/1?translated_name='
      },
      { id: 2, img: gene02, title: 'Cannect Gene', link: 'https://www.cannect.life/teste-genetico' }
    ]
  }
}

const AccordionProfessionalsData = [
  {
    title: 'Como aprender sobre prescrição de cannabis?',
    text: `Se você está interessado em aprender sobre as nuances da prescrição de Cannabis,
     a Cannect oferece cursos através da sua vertical de educação
     <a href="https://www.drcannabis.com.br/?utm_source=pagina-faq&utm_medium=cta&utm_id=cannect" target="_blank"> Dr. Cannabis</a>
     , que podem ajudar a construir uma base sólida para entender a cannabis medicinal e a prescrever de forma prática.
     <br/>
     <br/>
    Além disso, a equipe da Dr. Cannabis
    oferece suporte especializado após o curso para garantir
    que você tenha as ferramentas e conhecimentos necessários para prescrever Cannabis com segurança e eficácia.
    `
  },
  {
    title: 'Como escolher o melhor produto para cada paciente?',
    text: `Cada paciente tem uma necessidade de um produto ou combinação de produtos que
    terá o melhor resultado. Por exemplo, seu paciente pode se beneficiar de um produto rico em CBD,
     mas com uma pequena quantidade de THC, ou um outro produto de seja balanceado entre as quantidades de CBD e THC.
     <br/>
     <br/>
    Também existem nuances de combinações diferentes de CBD e THC administradas em horários diferentes do dia,
    de acordo com o estilo de vida e necessidade de cada paciente.
    <br/>
    <br/>
    Para explicar todas essas especificidades, a Cannect agora tem uma vertical de educação, que oferece <a href="https://www.cannect.life/educa"> cursos </a> que
    além de dar a base para entender a Cannabis medicinal, ensina a prescrever de forma prática e eficiente, dando todo suporte depois do curso.
    <br/>
    <br/>
    Nosso ecossistema ainda dispõe do <a href="https://www.cannect.life/cuida"> Cannect Cuida </a>, uma ferramenta de acolhimento de pacientes para o monitoramento de
    tratamento, com uma equipe de profissionais da saúde pronta para atender.`
  },
  {
    title: 'Como dosar e orientar a administração de Cannabis medicinal para meus pacientes?',
    text: `
    A prescrição segura de Cannabis medicinal envolve não apenas compreender suas propriedades,
    mas também seguir algoritmos e estudos. Para ajudar médicos a adquirir essas habilidades,
    a Cannect oferece um <a href="https://www.drcannabis.com.br/?utm_source=pagina-faq&utm_medium=cta&utm_id=cannect" target="_blank">curso
    que ensina a prescrição de forma prática e eficiente</a>, além de
    fornecer suporte especializado após a conclusão do curso.
      <br/>
      <br/>
    Além disso, a Cannect mantém uma
    <a href="https://api.whatsapp.com/send/?phone=5511950811547&text&type=phone_number&app_absent=0" target="_blank">comunidade ativa de médicos prescritores parceiros,</a>
    onde
    são discutidos casos clínicos e compartilhadas as melhores práticas. Essa comunidade é um espaço
    valioso para troca de experiências e atualização constante sobre a prescrição de Cannabis medicinal.
      `
  },
  {
    title: 'Como a Cannect me auxilia junto aos pacientes?',
    text: `
    A Cannect está pronta para te auxiliar e otimizar o fluxo de trabalho de profissionais
     da saúde na prática clínica diária através de diversas solução e principalmente, através
     do Cannect Assistente e do Cannect Cuida.

    Nosso <a href="https://www.cannect.life/assistente" target="_blank"> assistente de prescrição</a>, uma ferramenta gratuita, facilita o processo de envio de receitas
     digitais aos pacientes, ao enviá-las de forma automática via e-mail e SMS.

    O <a href="https://www.cannect.life/educa"> Cannect Cuida </a> é um serviço de cuidado coordenado, resguardado por um protocolo desenvolvido por nossa
    equipe médica, que acompanha o tratamento dos pacientes no cotidiano, tirando dúvidas, garantindo
    eficácia do tratamento e bem estar dos pacientes.

      `
  }
]

export const ProfessionalFaq = {
  title: 'Para profissionais',
  accordionItems: AccordionProfessionalsData,
  journeyItems: {
    title: 'Profissional da Saúde',
    icon: <FirstAidKit />,
    cardItems: [
      {
        id: 1,
        img: professionalFaq,
        title: 'Cannect Profissionais',
        link: 'https://www.cannect.life/profissionais-parceiros-cannabis-medicinal'
      },
      {
        id: 2,
        img: productsFaq,
        title: 'Cannect Produtos',
        link: 'https://www.cannect.life/produtos/1?translated_name='
      },
      { id: 3, img: treatments02, title: 'Cannect Assistente', link: 'https://www.cannect.life/assistente' },
      { id: 4, img: gene02, title: '', link: 'https://www.cannect.life/teste-genetico' }
    ]
  }
}
