import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { usePrepareCart } from '@cannect/hooks/usePrepareCart'
import { MOBILE_WIDTH } from '@cannect/utils/constants'
import { useEffect, useState } from 'react'
import { formatReal } from 'utils/formatters'
import * as S from './styles'

interface GenericItem<T = Record<string, any>> {
  price?: number
  real_price?: number
  quantity: number
  data: T
  product?: any
  unitPrice?: string
}

interface PaymentProps {
  instalments: number
  method: 'boleto' | 'pix' | 'credit_card'
  installment_value: string
  interest_amount: string
  link: null | string
  installments?: number
}

interface ResumeOrderProps {
  items?: GenericItem[]
  freight?: number
  reshipping_value?: string
  subtotal?: number
  discountFreight?: number
  discountProduct?: number
  paymentData?: PaymentProps
  amount?: number
  title?: string
  originType?: string
  payments?: any[]
}

export function ResumeOrder({
  items = [],
  reshipping_value,
  freight,
  subtotal,
  discountFreight,
  discountProduct,
  paymentData,
  amount,
  title,
  originType,
  payments
}: ResumeOrderProps) {
  const not_value = 'R$ 0,00'
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [amountToPay, setAmountToPay] = useState<any>()
  const [valuePaid, setValuePaid] = useState<any>()
  const { paymentRetry } = usePrepareCart()
  const product_total = items.reduce((acc, item) => {
    const price = item?.unitPrice ?? item.price ?? item.real_price ?? item?.product?.real_price ?? 0
    return acc + price * item.quantity
  }, 0)
  console.log('payments', payments)
  useEffect(() => {
    if (payments) {
      let amountToPay = 0
      payments.map((payment) => {
        if (payment?.status === 'payment_refused' || payment?.status === 'failed') {
          amountToPay += Number(payment.amount / 100)
        } else {
          setValuePaid(Number(payment.amount / 100))
        }
      })
      setAmountToPay(amountToPay)
    }
  }, [payments])

  const validatePrice = (price?: number | string) => {
    if (price) {
      return formatReal(price)
    }

    return not_value
  }

  const newAmount = Number(product_total) + Number('145') - Number(discountFreight || 0) - Number(discountProduct || 0)

  return (
    <S.Wrapper className="component_resume-wrapper">
      <S.Heading>{title}</S.Heading>

      <S.ResumeList>
        <S.ResumeLine>
          <S.PriceTitle>Produtos ({items.length}) </S.PriceTitle>
          <S.PriceValue>{validatePrice(product_total)}</S.PriceValue>
        </S.ResumeLine>
        <S.ResumeLine>
          <S.PriceTitle>Descontos nos produtos </S.PriceTitle>
          <S.PriceValue>- {validatePrice(discountProduct)}</S.PriceValue>
        </S.ResumeLine>
        <S.ResumeLine>
          <S.PriceTitle>Subtotal </S.PriceTitle>
          <S.PriceValue>{validatePrice(product_total)}</S.PriceValue>
        </S.ResumeLine>
        <S.Separator />
        <S.ResumeLine>
          <S.PriceTitle>Custo de importação </S.PriceTitle>
          <S.PriceValue>R$ 145,00</S.PriceValue>
        </S.ResumeLine>

        <S.ResumeLine>
          {isMobile ? (
            <S.PriceTitle>
              Descontos no custo
              <br /> de importação{' '}
            </S.PriceTitle>
          ) : (
            <S.PriceTitle>Descontos no custo de importação </S.PriceTitle>
          )}

          <S.PriceValue>- {validatePrice(discountFreight)}</S.PriceValue>
        </S.ResumeLine>

        {!!paymentData?.installment_value && (
          <>
            <S.Separator />
            <S.ResumeLine>
              <S.PriceTitle>Pagamento </S.PriceTitle>
              <S.PriceValue>
                {paymentData.instalments || paymentData?.installments || 1}x
                {validatePrice(Number(paymentData.installment_value))}
              </S.PriceValue>
            </S.ResumeLine>
          </>
        )}
        <S.Separator />
        <S.ResumeLine>
          <S.PriceTitle isTotal>
            <strong>{originType === 'REENVIO' ? 'Total do Reenvio' : 'Total'}</strong>
          </S.PriceTitle>
          <S.PriceValue>{validatePrice(originType === 'REENVIO' ? reshipping_value : newAmount)}</S.PriceValue>
        </S.ResumeLine>
        {paymentRetry && (
          <>
            <S.ResumeLine>
              <S.PriceTitle isTotal>
                <strong>Total do valor pago</strong>
              </S.PriceTitle>
              <S.PriceValue>{validatePrice(valuePaid)}</S.PriceValue>
            </S.ResumeLine>
            <S.ResumeLine>
              <S.PriceTitle isTotal>
                <strong>Valor a ser pago</strong>
              </S.PriceTitle>
              {/* <S.PriceValue>{formatPrice(payments ?? [])}</S.PriceValue> */}
              <S.PriceValue>{validatePrice(amountToPay)}</S.PriceValue>
            </S.ResumeLine>
          </>
        )}
      </S.ResumeList>
    </S.Wrapper>
  )
}
