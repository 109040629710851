export const TEXTINPUTDATA = {
  RECEITA: {
    title: 'Receita médica',
    description:
      'Certifique-se de que a receita tenha sido emitida nos últimos 6 meses e esteja assinada por um profissional da saúde com CRM ou CRO válido.'
  },
  IDENTIDADE: {
    title: 'Documento de identidade (RG, CPF ou CNH)',
    description: 'Certifique-se que o CPF está regular na receita federal'
  },
  RESIDENCIAL: {
    title: 'Comprovante de residência',
    description:
      'Comprovante emitido nos últimos 5 meses, em nome do paciente que consta na receita e com CEP do endereço de entrega. '
  },
  VINCULO: {
    title: 'Comprovante de vínculo ou dependente',
    description: ''
  },
  ANVISA: {
    title: 'Autorização ANVISA',
    description:
      'Caso opte por emitir a autorização, veja nosso passo a passo abaixo! No entanto, não se preocupe, caso não envie agora, após o pagamento um atendente irá te ajudar com isso!'
  }
}
