import { Button, Icons, Typography } from '@cannect/new-components/atoms'
import Cards from '@cannect/new-components/molecules/Cards'
import {
  schedulingsOpinionsClara,
  schedulingsOpinionsCTO,
  schedulingsOpinionsJoao,
  schedulingsOpinionsMarcos,
  schedulingsPatient
} from 'assets/img'
import { CalendarArrowUp, CreditCard, KeyRound, Stethoscope, WalletMinimal } from 'lucide-react'
import { useHistory } from 'react-router'
import { CommonQuestionsSection } from '../CommonQuestionsSection'
import { useEffect, useRef, useState } from 'react'

export const PERSONS_OPINIONS_LIST = [
  {
    photo: schedulingsOpinionsClara,
    name: 'Clara Azevedo',
    role: 'Professora',
    description:
      '“Fiquei impressionada com a rapidez do agendamento. Consegui marcar minha consulta em poucos minutos e me senti acolhida em todas as etapas.”'
  },
  {
    photo: schedulingsOpinionsJoao,
    name: 'João Carvalho',
    role: 'Designer',
    description:
      '“O sistema de consultas é bem intuitivo. Conversei com uma médica que foi super profissional. Recomendo para quem busca uma orientação séria.”'
  },
  {
    photo: schedulingsOpinionsMarcos,
    name: 'Marcos Nogueira',
    role: 'Advogado',
    description:
      '“Desde o início, percebi o cuidado da Cannect com os clientes. O médico que me atendeu quebrou todos os preconceitos que eu tinha sobre a cannabis medicinal.”'
  }
]

export const SchedulingFeaturesSections = () => {
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeIndexCards, setActiveIndexCards] = useState(0)

  return (
    <div className="mx-auto max-w-[1196px] px-2">
      <section className="flex flex-col items-center gap-[32px] py-section text-center lg:gap-[42px]">
        <Typography.Heading type="headingThree" className="leading-15 text-primary-700 lg:text-4xl">
          Vantagens do agendamento Cannect
        </Typography.Heading>
        <div
          onScroll={(e) => {
            const scrollX = e.currentTarget.scrollLeft
            const index = Math.floor(scrollX / 250)
            setActiveIndexCards(index)
          }}
          className="flex w-[1164px] max-w-full flex-row items-start justify-start gap-[24px] overflow-x-auto">
          <Cards.HomeFeature
            classNames={{
              title: 'text-[20px]',
              subtitle: 'md:text-base'
            }}
            icon={CalendarArrowUp}
            title="Agenda atualizada em tempo real"
            subtitle="Receba automaticamente os horários disponíveis do profissional de sua escolha."
          />
          <Cards.HomeFeature
            classNames={{
              title: 'text-[20px]',
              subtitle: 'md:text-base'
            }}
            icon={Stethoscope}
            title="Consulta personalizada"
            subtitle="Encontre os especialistas que mais se encaixem à sua necessidade."
          />
          <Cards.HomeFeature
            classNames={{
              title: 'text-[20px]',
              subtitle: 'md:text-base'
            }}
            icon={KeyRound}
            title="Conforto e privacidade"
            subtitle="Realize sua consulta por videochamada, no lugar de sua preferência."
          />
          <Cards.HomeFeature
            classNames={{
              title: 'text-[20px]'
            }}
            icon={WalletMinimal}
            title="Formas de pagamento"
            subtitle={
              <div className="flex flex-col gap-2">
                <Typography.Text className="flex items-center gap-1 leading-[150%]">
                  <CreditCard /> Cartão de crédito
                </Typography.Text>
                <Typography.Text className="flex items-center gap-1 leading-[150%]">
                  <Icons.Pix />
                  Pix
                </Typography.Text>
                <Typography.Text className="mt-2 leading-[150%]">*Parcele em até 2x sem juros</Typography.Text>
              </div>
            }
          />
        </div>
      </section>
      <div className="align-center mb-8 mt-8 flex justify-center space-x-2 md:sr-only">
        {[1, 2, 3, 4].map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 rounded-full transition-all ${
              index === activeIndexCards ? 'scale-110 bg-primary' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
      <section className="flex flex-col items-center gap-[32px] text-center lg:gap-[42px]">
        <Typography.Heading type="headingThree" className="leading-12 text-primary-700 lg:text-4xl">
          O que as pessoas acham do nosso agendamento
        </Typography.Heading>
        <div
          onScroll={(e) => {
            const scrollX = e.currentTarget.scrollLeft
            const index = Math.floor(scrollX / 250)
            setActiveIndex(index)
          }}
          className="flex w-[1164px] max-w-full flex-row items-start justify-start gap-[24px] overflow-x-auto">
          {PERSONS_OPINIONS_LIST.map((person) => (
            <Cards.SchedulingOpinions key={person.name} personDetail={person} />
          ))}
          <div className="flex h-[354px] w-[273px] items-center gap-4 rounded-2xl bg-primary-600 pr-4 text-left">
            <img
              className="h-[272px] w-[113px] rounded object-cover"
              alt="scheduling-opinions-cto"
              src={schedulingsOpinionsCTO}
            />
            <div className="flex flex-col items-start">
              <Typography.Heading type="headingFour" className="text-gray-50" weight="regular">
                Comece uma vida melhor
              </Typography.Heading>
              <Typography.Text type="paragraphTwo" className="mt-1 text-gray-50">
                Fale com um dos nossos especialistas
              </Typography.Text>
              <Button
                size="xs"
                className="mt-6 rounded-md"
                onClick={() =>
                  window.open(
                    'https://atendimento.cannect.life/atendimento-cannect?utm_medium=organic&utm_source=whatsa%5B%E2%80%A6%5Dcannect_organic_geral&utm_content=cannect&utm_term=botaos',
                    '_blank'
                  )
                }>
                Clique aqui
              </Button>
            </div>
          </div>
        </div>
      </section>
      <div className="align-center mt-8 flex justify-center space-x-2 md:sr-only">
        {[...PERSONS_OPINIONS_LIST, {}].map((_, index) => (
          <div
            key={index}
            className={`mb-8 h-3 w-3 rounded-full transition-all ${
              index === activeIndex ? 'scale-110 bg-primary' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
      <section className="flex flex-col items-center gap-[32px] py-section text-center">
        <div className="flex h-[651px] w-full flex-col gap-16 rounded-2xl bg-primary-200 p-8 text-left lg:h-[388px] lg:flex-row">
          <img
            className="h-[189px] w-[255px] rounded object-cover lg:h-[324px] lg:w-[574px]"
            alt="scheduling-patient-cto"
            src={schedulingsPatient}
          />
          <div className="flex max-w-[420px] flex-col items-start gap-4">
            <Typography.Heading type="headingFour" className="leading-10 md:leading-[43px] lg:text-4xl">
              Seu tratamento não termina na compra dos produtos!
            </Typography.Heading>
            <Typography.Text>
              O <strong>Cannect Cuida</strong> é nosso programa de enfermagem exclusivo. Oferecemos gratuitamente um
              espaço de assistência e acolhimento, alinhados com as orientações do seu prescritor.
            </Typography.Text>
            <Button className="mt-6" onClick={() => history.push('/cuida')}>
              Confira os benefícios
            </Button>
          </div>
        </div>
      </section>

      <CommonQuestionsSection />
    </div>
  )
}
