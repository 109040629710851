import api from '../../services/api'
import { FetchProductFiltersResponse, FetchRoutesOfAdministrationResponse, FetchTypeStudiesResponse } from './types'

export const SUPPLIER_FILTERS = [
  {
    id: 1,
    name: 'Importados',
    description: `Produtos importados de acordo com a <a target="_blank" rel="noopener noreferrer" href="https://www.in.gov.br/en/web/dou/-/resolucao-rdc-n-660-de-30-de-marco-de-2022-389908959" >RDC 660/2022</a>, com entrega em até 15 dias úteis.`,
    checked: true
  },
  {
    id: 18,
    name: 'Entrega imediata',
    description: `Farmácia online operando de acordo com a <a href="https://bvs.saude.gov.br/bvs/saudelegis/anvisa/2019/rdc0327_09_12_2019.pdf" target="_blank">RDC 327/2019</a> da ANVISA, com entrega em até 72h.`,
    checked: true
  },
  {
    id: 27,
    name: 'Suplementos',
    description: `Suplementos alimentícios, envio nacional, sem necessidade de receita médica. <a href="/suplementos">Saiba mais</a>`,
    checked: true
  }
]

export const fetchTypeStudies = async () => {
  try {
    const {
      data: { shapes }
    } = await api.get<FetchTypeStudiesResponse>(`/type-studies`)

    return shapes
  } catch (e) {
    console.log(e)
    return []
  }
}

export const fetchVeiculos = async () => {
  return [
    { id: 'TCM', name: 'TCM' },
    { id: 'Óleo de semente de cânhamo', name: 'Óleo de semente de cânhamo' },
    { id: 'Óleo de coco', name: 'Óleo de coco' },
    { id: 'Óleo de girassol', name: 'Óleo de girassol' },
    { id: 'Óleo de palma', name: 'Óleo de palma' },
    { id: 'Outros óleos', name: 'Outros óleos' },
    { id: 'Azeite', name: 'Azeite' },
    { id: 'Hidrossolúvel', name: 'Hidrossolúvel' },
    { id: 'Nanotecnologia', name: 'Nanotecnologia' }
  ]
}

export const fetchExtractionMethod = () => {
  return [
    { id: 'Alcoólica', name: 'Alcoólica' },
    { id: 'Alcoólica à frio', name: 'Alcoólica à frio' },
    { id: 'Fluído supercrítico', name: 'Fluído supercrítico' },
    { id: 'Hidrocarbonetos', name: 'Hidrocarbonetos' }
  ]
}

export const fetchLevel = () => {
  return [
    { id: 'Básico', name: 'Básico' },
    { id: 'Essencial', name: 'Essencial' },
    { id: 'Plus', name: 'Plus' },
    { id: 'Premium', name: 'Premium' }
  ]
}

export const fetchGMP = () => {
  return [
    { id: 'Nutricional (cGMP)', name: 'Nutricional (cGMP)' },
    { id: 'Farmacêutico (EU-GMP)', name: 'Farmacêutico (EU-GMP)' },
    { id: 'Farmacêutico (cGMP)', name: 'Farmacêutico (cGMP)' },
  ]
}

export const fetchRoutesOfAdministration = async () => {
  try {
    const {
      data: { routeOfAdministrations }
    } = await api.get<FetchRoutesOfAdministrationResponse>(`/routeofadministrations`)

    return routeOfAdministrations
  } catch (e) {
    console.log(e)
    return []
  }
}

export const fetchProductFilters = async () => {
  try {
    const {
      data: {
        filters: { category_id, ...filters }
      }
    } = await api.get<FetchProductFiltersResponse>('/product-filters')

    return filters
  } catch (e) {
    console.log(e)
    return {}
  }
}
