import Texts from 'components/Text'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { useMemo } from 'react'
import { formatPriceToNumber } from 'utils/formatters'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { OrderTypes } from '../../../types/OrderTypes'
import { MOBILE_WIDTH } from '../../../utils/constants'
import { Container, Title } from './styles'

const FREE_SHIPPING_MINIMUM = 2500

interface OrderSummaryProps {
  subtotal: number
  total: number
}

const getShippingCost = (shippingValue: string): number => {
  if (parseFloat(shippingValue) === 0) return 0
  return 145
}

const computeOrderSummary = (items: OrderTypes[], shipping: number, discount: number): OrderSummaryProps => {
  const subtotal = items.reduce((acc, item) => acc + item.unitPrice * item.quantity, 0)
  const total = subtotal - discount
  return { subtotal, total }
}

function Frete() {
  const { lastOrderCreated } = usePrepareCart()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  if (!lastOrderCreated) return null

  const { items } = lastOrderCreated

  const isAllSupplements = items.every((item: any) => item?.product?.class_id === 6 && item.product?.brand_id === 108)

  const isFreeShippingFromAPI = parseFloat(lastOrderCreated.shipping || '0') === 0
  const shipping = getShippingCost(lastOrderCreated.shipping || '0')
  const discount = parseFloat(lastOrderCreated.discount || '0')

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const orderSummary = useMemo(
    () => computeOrderSummary(lastOrderCreated.items, shipping, discount),
    [lastOrderCreated.items, shipping, discount]
  )

  const remainingForFreeShipping = isFreeShippingFromAPI ? 0 : FREE_SHIPPING_MINIMUM - orderSummary.total

  return (
    <Container isMobile={isMobile}>
      <Title>{isAllSupplements ? 'FRETE GRÁTIS' : 'CUSTO DE IMPORTAÇÃO'}</Title>
      {!isAllSupplements ? (
        <div className="input">
          <input
            type="range"
            value={remainingForFreeShipping <= 0 ? FREE_SHIPPING_MINIMUM : orderSummary.total}
            max={FREE_SHIPPING_MINIMUM}
          />
          <Texts>
            Faltam <b>{formatPriceToNumber(Math.max(remainingForFreeShipping, 0))}</b> para{' '}
            <b>Custo de importação grátis</b>
          </Texts>
        </div>
      ) : (
        <Texts>Frete grátis para compras exclusivamente de suplementos.</Texts>
      )}
    </Container>
  )
}

export default Frete
