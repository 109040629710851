import { useGetProductById } from '@cannect/services/resources/products'
import { useMemo } from 'react'
import { TProductDetailsDialog } from './types'

export const useProductDetailsDialog = ({ productId }: TProductDetailsDialog) => {
  const { data, isLoading } = useGetProductById(productId)

  const productDetails = useMemo(() => data?.product, [data?.product])

  return {
    productDetails,
    isLoading
  }
}
