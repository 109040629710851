export enum Texts {
    PURE_JAMAICA_MESSAGE = 'Atenção, cliente! A marca Pure Jamaica possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 30 dias. Agradecemos pela compreensão!', 
    NEX_BIOPHARMA_MESSAGE = 'Atenção, cliente! A marca Nex Biopharma possui um processo de exportação a partir da Suiça, o que ocasiona um maior tempo de entrega. Para produtos da marca, o tempo estimado de entrega é de 30 dias. Agradecemos pela compreensão!',
    HEMP_MEDS_MESSAGE = 'Atenção, cliente! A marca HempMeds possui um processo de exportação a partir dos Estados Unidos, o que ocasiona um maior tempo de entrega. Para produtos da marca, o tempo estimado de entrega é de 16 dias. Agradecemos pela compreensão!',
    SENSIA_MESSAGE = 'Atenção, cliente! A marca Sensia possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 14 dias. Agradecemos pela compreensão!',
    GREENS_MESSAGE = 'Atenção, cliente! A marca Greens possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 40 dias. Agradecemos pela compreensão!',
    FLOWER_MED_MESSAGE = 'Atenção, cliente! A marca FlowerMed possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 20 dias. Agradecemos pela compreensão!',
    EXTRACTOS_DEL_SUR_MESSAGE = 'Atenção, cliente! A marca Extractos del Sur possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 20 dias. Agradecemos pela compreensão!',
    ENDOGEN_MESSAGE = 'Atenção, cliente! A marca Endogen possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 7 dias. Agradecemos pela compreensão!',
    BIOCASE_MESSAGE = 'Atenção, cliente! A marca Biocase possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 20 dias. Agradecemos pela compreensão!',
    BEHEMP_MESSAGE = 'Atenção, cliente! A marca Behemp possui um processo de exportação próprio no país, o que pode aumentar o prazo de entrega. Para produtos da marca, o tempo estimado de entrega é de 23 dias. Agradecemos pela compreensão!',
}

export enum RedirectUrls {
    WHATSAPP_SCHEDULE_CONSULTATION = 'https://api.whatsapp.com/send/?phone=5511982711992&text=Ol%C3%A1%2C+gostaria+de+agendar+uma+consulta+com+especialista+em+cannabis+medicinal%21+-+Inicie+a+conversa+para+ser+atendido&type=phone_number&app_absent=0',
}
