import { LoadingOutlined } from '@ant-design/icons'
import LoadingContainer from 'components/LoadingContainer'
import format from 'date-fns/format'
import { Button } from 'design-cannect'
import Heading from 'new-components/Heading'
import { AwbsData, PackagesData } from 'pages/Account/components/pages/MyOrders/types'
import { useEffect, useState } from 'react'
import { BsCreditCard2Back, BsListCheck, BsPatchCheck, BsSearch, BsTag, BsTruck } from 'react-icons/bs'
import { HiOutlineDocumentCheck } from 'react-icons/hi2'
import { SlSocialDropbox } from 'react-icons/sl'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import ProductList from '../Product'
import { Resume } from '../Resume'
import * as S from './styles'

interface OrderStatusType {
  color: string
  date: string
  status: string
  translated_name: string
}

const status = [
  {
    id: 1,
    idName: ['awaiting_payment', 'paid'],
    name: 'Status do pagamento.',
    checkedName: 'Pagamento aprovado',
    activeName: 'Aguardando pagamento',
    icon: <BsCreditCard2Back />,
    checked: true,
    isVerified: true,
    label: 'Aguardando pagamento',
    nextStepName: ['waiting_documentation']
  },
  {
    id: 2,
    idName: ['waiting_documentation', 'waiting_approval', 'waiting_anvisa', 'disapproved_document'],
    name: 'Validações de documentos.',
    checkedName: 'Documentos aprovados',
    activeName: 'Documentos em validação',
    icon: <HiOutlineDocumentCheck />,
    checked: false,
    isVerified: false,
    label: 'Documentos aprovados',
    nextStepName: ['approved_documents']
  },
  {
    id: 4,
    idName: [
      'dispatched',
      'created',
      'approved_documents',
      'approved',
      'waiting_separation',
      'in_separation',
      'preparing_shipping',
      'separated',
      'ready_shipping',
      'waiting_stock',
      'sented',
      'shipping_supplier'
    ],
    name: 'Status do pacote',
    checkedName: 'Pacote enviado',
    activeName: 'Pacote em preparação',
    icon: <SlSocialDropbox />,
    checked: false,
    isVerified: false,
    label: 'Pacote enviado',
    nextStepName: ['dispatched', 'sented']
  },
  // {
  //   id: 5,
  //   idName: ['anvisa_br'],
  //   name: 'Chegou no Brasil',
  //   checkedName: 'Chegou no Brasil',
  //   activeName: 'A caminho do Brasil',
  //   icon: <BsFlag />,
  //   checked: false,
  //   isVerified: false,
  //   label: 'Chegou no Brasil',
  //   nextStepName: ['anvisa_br']
  // },
  {
    id: 6,
    idName: ['anvisa_exception', 'delay_release', 'anvisa_br'],
    name: 'Inspeção ANVISA',
    checkedName: 'Aprovado pela ANVISA',
    activeName: 'Em inspeção ANVISA',
    icon: <BsSearch />,
    checked: false,
    isVerified: false,
    label: 'Inspeção ANVISA',
    nextStepName: ['release_anvisa']
  },
  {
    id: 7,
    idName: ['release_anvisa'],
    name: 'Liberado pela ANVISA',
    checkedName: 'Liberado pela ANVISA',
    activeName: 'Aguardando liberação',
    icon: <BsListCheck />,
    checked: false,
    isVerified: false,
    label: 'Liberado pela ANVISA',
    nextStepName: ['delivered', 'in_transit', 'not_delivered', 'out_for_delivery']
  },
  {
    id: 8,
    idName: ['delivered', 'in_transit', 'not_delivered', 'out_for_delivery'],
    name: 'Pedido entregue',
    checkedName: 'Pedido Entregue',
    activeName: 'Aguardando Entrega',
    icon: <BsPatchCheck />,
    checked: false,
    isVerified: false,
    label: 'Pedido entregue',
    nextStepName: ['delivered']
  }
]

function OrderDetails() {
  const [orderDetails, setOrderDetails] = useState<any>({ items: [] })
  const [PackageDetails, setPackageDetails] = useState<any>({ packages: [] })
  const [orderStatus, setOrderStatus] = useState({} as OrderStatusType)
  const [statusPayment, setStatusPayment] = useState({} as OrderStatusType)
  const [timeLineStatus, setTimeLineStatus] = useState(status)
  const [loading, setLoading] = useState(false)
  const { cart_id } = useParams() as any
  const history = useHistory()
  const [activeStepIndex, setActiveStepIndex] = useState<number | null>(null)
  const [OrderPackage, setOrderPackage] = useState<PackagesData[]>([])
  const [OrderAwb, setOrderAwb] = useState<AwbsData[]>([])
  const [PackageCount, setPackageCount] = useState<number>(0)
  const isSmallScreen = window.innerWidth < 750
  const [Data, SetData] = useState<any>({})
  const [StatusMessage, setStatusMessage] = useState<string>('')
  const [DocumentExcept, setDocumentExcept] = useState<boolean>(false)
  const [StatusIndex, setStatusIndex] = useState<number>(0)
  const linkAtendimento =
    'https://api.whatsapp.com/send/?phone=5511982711992&text=Oi%2C+gostaria+de+iniciar+meu+tratamento%21&type=phone_number&app_absent=0'

  const getLastOrders = async (loading = true) => {
    setLoading(loading)
    try {
      const { data } = await api.post(`/order_status/${cart_id}`)
      if (data?.order) {
        const responsePackage = await api.get(`/packages/${data?.order?.id}/package-with-status`)
        const statusIndex = status.findIndex((item) => item.idName.includes(data?.order?.status?.order_status?.status))
        let orderStatus =
          statusIndex > 1
            ? responsePackage?.data?.packages[PackageCount]?.statusLogs?.slice(-1)[0].status ||
              data?.order?.status?.order_status
            : data?.order?.status?.order_status

        const activeStepIndex =
          statusIndex > 1
            ? data?.order?.packages[PackageCount]?.statusLogs
              ? status.findIndex((item) => item.idName.includes(orderStatus.name))
              : status.findIndex((item) => item.idName.includes(orderStatus?.status))
            : status.findIndex((item) => item.idName.includes(orderStatus?.status))

        const packageDetails = responsePackage?.data?.packages
        const payment_status = data?.payment
        const OrderPackage = packageDetails.length === 0 ? data?.order?.packages : packageDetails
        orderStatus = {
          ...orderStatus,
          statusData: Array.isArray(packageDetails[PackageCount]?.statusLogs)
            ? packageDetails[PackageCount]?.statusLogs?.slice(-1)[0]?.createdAt
            : data?.order?.status?.order_status?.createdAt
        }

        setTimeLineStatus((prevStatus) =>
          prevStatus.map((item, index) => ({
            ...item,
            checked: orderStatus.translated_name === 'Entregue' || index < activeStepIndex,
            isVerified: orderStatus.translated_name === 'Entregue' || index < activeStepIndex,
            statusCreatedAt:
              packageDetails.length === 0
                ? ''
                : item.nextStepName
                    .map((statusName: string) => {
                      return packageDetails[PackageCount]?.statusLogs
                        ?.filter((log: any) => log.status.name === statusName)
                        .map((log: any) => log.createdAt)
                    })
                    .flat()
                    .reduce((min, current) => {
                      return new Date(current) < new Date(min) ? current : min
                    }, new Date())
          }))
        )
        setPackageDetails(packageDetails)
        messageDefine(orderStatus, data?.order)
        setOrderStatus(orderStatus)
        setOrderDetails(data?.order)
        setStatusPayment(payment_status)
        setActiveStepIndex(activeStepIndex)
        setStatusIndex(statusIndex)
        if (data?.order?.packages[PackageCount]?.statusLogs) {
          setOrderPackage(OrderPackage)
        } else {
          setOrderAwb(OrderPackage)
        }
        SetData(data)
      }
    } catch (e: any) {
      toast.error(
        e.response.status === 404 ? 'Pedido não encontrado no CPF do usuário' : 'Erro na busca dos detalhes do pedido'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (cart_id) {
      getLastOrders()
    }
  }, [cart_id])

  const getStatusCss = (checked: boolean, isVerified: boolean, isPending: boolean) => {
    if (isPending) {
      return 'pending'
    }
    if (checked && isVerified) {
      return 'success'
    }
    if (!checked && isVerified) {
      return 'danger'
    }
    return 'default'
  }

  if (loading) return <LoadingContainer loading />

  function messageDefine(packageStatus: any, orderData: any) {
    console.log(packageStatus)
    const status = packageStatus?.translated_name
    let message = ''

    if (status === 'Aguardando Documentos') message = 'Você ainda não encaminhou todos os documentos.'
    if (status === 'Aguardando Anvisa')
      message = 'Recebemos todos os seus documentos, agora estamos gerando sua autorização Anvisa'
    if (status === 'Aguardando Aprovação')
      message = 'Recebemos todos os seus documentos, em breve entraremos em contato com mais informações.'
    if (status === 'Documento reprovado') message = 'Houve um problema com seus documentos.'
    if (status === 'Documentos Aprovados') message = 'Tudo certo com seus documentos.'
    if (status === 'Aguardando estoque') message = 'Há item(ns) em falta no estoque, mas já solicitamos ao fornecedor.'
    if (
      status === 'Criado' ||
      status === 'Aprovado' ||
      status === 'Preparando Envio' ||
      status === 'Aguardando separação'
    )
      message = 'Seu pacote foi enviado para separação.'
    if (status === 'Em separação') message = 'Seu pacote está sendo separado.'
    if (status === 'Separado' || status === 'Pronto para envio' || status === 'Aguardando Envio')
      message = 'Seu pacote foi embalado. Em breve será enviado para transportadora.'
    if (status === 'Enviado' || status === 'Enviado') message = 'Seu pacote já foi enviado. Em breve chegará ao Brasil.'
    if (status === 'Anvisa BR' || status === 'Exceção Anvisa' || status === 'Análise Anvisa')
      message = 'Seu pacote está em análise pela ANVISA. Esta etapa dura entre 7 e 15 dias úteis.'
    if (status === 'Liberado Anvisa')
      message = 'Seu pacote foi liberado pela anvisa. Em breve entrará em rota de entrega.'
    if (status === 'Em trânsito' || status === 'Não entregue')
      message = `Seu pacote está em trânsito. Destino: ${orderData?.street}, ${orderData?.address_number}`
    if (status === 'Saiu para entrega')
      message = `Seu pacote saiu para entrega. Destino: ${orderData?.street}, ${orderData?.address_number}`
    if (status === 'Entregue') message = 'Seu pedido foi entregue no dia'

    setStatusMessage(`${message} - ${format(new Date(packageStatus?.statusData), 'dd/MM/yyyy - HH:mm')}.`)
    if (status === 'Aguardando Documentos' || status === 'Documento reprovado') setDocumentExcept(true)
  }

  function packageUpdate(packIndex: number) {
    if (PackageDetails[packIndex]?.statusLogs?.slice(-1)[0].status) {
      let orderStat =
        StatusIndex > 1 ? PackageDetails[packIndex]?.statusLogs?.slice(-1)[0].status : Data?.order?.status?.order_status

      orderStat = {
        ...orderStat,
        statusData: PackageDetails[packIndex]?.statusLogs?.slice(-1)[0].createdAt
      }

      const activeStepIndex =
        StatusIndex > 1
          ? status.findIndex((item) => item.idName.includes(orderStat.name))
          : status.findIndex((item) => item.idName.includes(orderStat.status))

      setTimeLineStatus((prevStatus) =>
        prevStatus.map((item, index) => ({
          ...item,
          checked: orderStat.translated_name === 'Entregue' || index < activeStepIndex,
          isVerified: orderStat.translated_name === 'Entregue' || index < activeStepIndex,
          statusCreatedAt:
            PackageDetails.length === 0
              ? ''
              : item.nextStepName
                  .map((statusName: string) => {
                    return PackageDetails[PackageCount]?.statusLogs
                      ?.filter((log: any) => log.status.name === statusName)
                      .map((log: any) => log.createdAt)
                  })
                  .flat()
                  .reduce((min, current) => {
                    return new Date(current) < new Date(min) ? current : min
                  }, new Date())
        }))
      )

      setOrderStatus(orderStat)
      setActiveStepIndex(activeStepIndex)
      setPackageCount(packIndex)
      messageDefine(orderStat, orderDetails)
    }
  }

  let filteredItems = PackageDetails[PackageCount]?.statusLogs
    ? orderDetails?.items.filter((item: any) =>
        PackageDetails[PackageCount]?.packages_items.some(
          (packageItem: { product_id: number }) =>
            packageItem.product_id === (item?.product?.kit_product?.id ? item.product.kit_product.id : item.product.id)
        )
      )
    : orderDetails?.items

  const duplicatesDeleter = new Set()
  const uniqueItems = []

  for (const item of filteredItems) {
    const value = item?.product?.kit_product?.id || item?.product?.id
    if (!duplicatesDeleter.has(value)) {
      duplicatesDeleter.add(value)
      uniqueItems.push(item)
    }
  }

  if (PackageDetails[PackageCount]?.statusLogs) {
    filteredItems = uniqueItems.map((item: any) => {
      const findItem = PackageDetails[PackageCount]?.packages_items.find(
        (packageItem: any) =>
          packageItem.product_id === (item?.product?.kit_product?.id ? item.product.kit_product.id : item.product.id)
      )

      const quantity = findItem ? findItem.quantity_item_for_package : findItem.quantity
      const unitPrice = item?.product?.kit_product?.id
        ? item?.product?.kit_product?.price || item?.product?.kit_product?.real_price
        : item?.unitPrice

      const product = item?.product?.kit_product?.id ? item?.product?.kit_product : item?.product

      return {
        ...item,
        amount: String(unitPrice * quantity),
        quantity: Number(quantity),
        unitPrice: String(unitPrice),
        product
      }
    })
  }

  let isSupplements: boolean | undefined

  if (PackageDetails.length > 0) {
    const packageItems = PackageDetails[PackageCount]?.packages_items

    if (packageItems.length > 0) {
      isSupplements = packageItems.every((item: any) => item?.class_id === 6 && item?.brand_id === 108)
    }
  } else {
    isSupplements = orderDetails?.items.every(
      (item: any) => item?.product.class_id === 6 && item?.product?.brand_id === 108
    )
  }
  const validActiveName = (item: any, index: any) => {
    if (index === activeStepIndex && orderStatus.translated_name !== 'Entregue') {
      if (orderStatus.translated_name === 'Enviado') {
        return 'Pacote enviado'
      }

      if (orderStatus.translated_name === 'Aguardando Documentos') {
        return 'Aguardando documentos'
      }

      return item.activeName
    }

    if (item.checked) {
      return item.checkedName
    }

    return item.name
  }

  return (
    <S.ContainerContent isSmallScreen={isSmallScreen}>
      <div style={{ display: isSmallScreen ? 'none' : 'relative' }} className="button items-center py-2">
        <Button onClick={() => history.push('/conta?pagina=pedidos-todos')}>Voltar para a lista de pedidos</Button>
      </div>
      <S.spacingDiv isSmallScreen={isSmallScreen}>
        <Heading defaultColor="darkGrey" fontSize="22px">
          Resumo da compra
        </Heading>
      </S.spacingDiv>
      <S.spacingDiv isSmallScreen={isSmallScreen}>
        <Heading defaultColor="darkGrey" fontSize="16px">
          N° do Pedido <span>{cart_id}</span>
        </Heading>
      </S.spacingDiv>
      <S.spacingDiv isSmallScreen={isSmallScreen}>
        {OrderPackage.length > 0 &&
          OrderPackage?.map((pack: PackagesData, index: number) => (
            <S.Button isActive={index === PackageCount} key={index} onClick={() => packageUpdate(index)}>
              Pacote {index + 1} - #{pack.id}
            </S.Button>
          ))}
        {OrderAwb.length > 0 &&
          OrderAwb?.map((pack: AwbsData, index: number) => (
            <S.Button isActive={index === PackageCount} key={index} onClick={() => packageUpdate(index)}>
              Pacote {index + 1}- #{pack.id}
            </S.Button>
          ))}
        <S.lineDivisor />
      </S.spacingDiv>
      {(OrderPackage.length > 0 && OrderPackage[PackageCount]?.carrier_name !== 'A definir' && (
        <S.spacingDiv isSmallScreen={isSmallScreen}>
          <S.Header>
            <BsTruck size={30} />
            <span>
              Transportadora: <strong>{OrderPackage[PackageCount]?.carrier_name}</strong>
            </span>
          </S.Header>
        </S.spacingDiv>
      )) ||
        (OrderAwb.length > 0 && OrderAwb[PackageCount]?.shippingCompany && (
          <S.spacingDiv isSmallScreen={isSmallScreen}>
            <S.Header>
              <BsTruck size={30} />
              <span>
                Transportadora: <strong>{OrderAwb[PackageCount]?.shippingCompany}</strong>
              </span>
            </S.Header>
          </S.spacingDiv>
        ))}

      {(OrderPackage.length > 0 || OrderAwb.length > 0) &&
        (OrderAwb[PackageCount]?.trackingNumber || OrderPackage[PackageCount]?.tracking_number) && (
          <S.spacingDiv isSmallScreen={isSmallScreen}>
            <S.Header>
              <BsTag size={30} />
              <span>
                Código de Rastreio:{' '}
                <strong>
                  {!OrderPackage
                    ? OrderAwb[PackageCount]?.trackingNumber || 'A definir'
                    : OrderPackage[PackageCount]?.tracking_number || 'A definir'}
                </strong>
              </span>
            </S.Header>
          </S.spacingDiv>
        )}

      <S.spacingDiv isSmallScreen={isSmallScreen}>
        <S.Header>
          <span>
            Ficou com alguma dúvida? Entre em contato conosco{' '}
            <S.LinkLabel href={linkAtendimento} target="_blank">
              clicando aqui!
            </S.LinkLabel>
          </span>
        </S.Header>
      </S.spacingDiv>

      <S.ContentTimeLine>
        {timeLineStatus.map((item: any, index: number) => {
          if (item.id === 1) {
            return (
              <S.Wrapper
                key={item.id}
                status={getStatusCss(
                  statusPayment?.status !== 'refused' ? item.checked : false,
                  item.isVerified,
                  index === activeStepIndex || !statusPayment
                )}
                isActive={index === activeStepIndex}>
                <div className="wrapper">
                  <div className="title">
                    <div className="icon">{item.icon}</div>
                    <div>
                      <p className="name">
                        {statusPayment?.status === 'paid' && <p className="label">Pagamento aprovado</p>}
                        {statusPayment?.status === 'refused' && <p className="label">Pagamento recusado</p>}
                        {statusPayment?.status === 'waiting_payment' && <p className="label"> Aguardando pagamento</p>}
                        {!statusPayment && <p className="label"> Aguardando pagamento</p>}
                      </p>
                    </div>
                  </div>
                  <S.BadgeIcon className="badge">
                    {index === activeStepIndex && (
                      <span className="spin">
                        <LoadingOutlined spin />
                      </span>
                    )}
                  </S.BadgeIcon>
                </div>
                <div className="line" />
                <div className="title_label">
                  {statusPayment?.status === 'paid' && (
                    <p className="label">
                      Pagamento aprovado no dia {format(new Date(Data?.payment?.updatedAt), 'dd/MM/yyyy - HH:mm')}
                    </p>
                  )}
                  {statusPayment?.status === 'payment_refused' && <p className="label">Pagamento recusado</p>}
                  {statusPayment?.status === 'waiting_payment' && <p className="label"> Aguardando pagamento</p>}
                </div>
              </S.Wrapper>
            )
          }

          if (item.id === 2 && isSupplements) {
            return null
          }

          return (
            <S.Wrapper
              key={item.id}
              status={getStatusCss(item.checked, item.isVerified, !item.checked && index === activeStepIndex)}
              isActive={!item.checked && index === activeStepIndex}>
              <div className="wrapper">
                <div className="title">
                  <div className="icon">{item.icon}</div>
                  <div>
                    <p className="name">{validActiveName(item, index)}</p>
                  </div>
                </div>
                <S.BadgeIcon className="badge">
                  {!item.checked && index === activeStepIndex && (
                    <span className="spin">
                      <LoadingOutlined spin />
                    </span>
                  )}
                </S.BadgeIcon>
              </div>
              <div className="line" />
              <div className="title_label">
                {item.id === 2 && DocumentExcept && (
                  <S.LinkLabel href={`/validacao-documentos/${orderDetails?.id}`} target="_blank">
                    Enviar documentos
                  </S.LinkLabel>
                )}
                {item.checked ? (
                  <p className="label">
                    {index === activeStepIndex && item.id === 8 && orderStatus?.translated_name === 'Entregue' && (
                      <span>
                        Seu pedido foi entregue no dia{' - '}
                        {format(
                          new Date(
                            Array.isArray(PackageDetails[PackageCount]?.statusLogs) &&
                            PackageDetails[PackageCount]?.statusLogs.length > 0
                              ? PackageDetails[PackageCount]?.statusLogs?.slice(-1)[0]?.createdAt
                              : orderStatus?.date
                          ),
                          'dd/MM/yyyy - HH:mm'
                        )}
                      </span>
                    )}
                    {item.id !== 8 &&
                      `${item.label} - ${format(new Date(item?.statusCreatedAt || orderStatus?.date), 'dd/MM/yyyy - HH:mm')}`}
                  </p>
                ) : (
                  <p className="label">
                    {index === activeStepIndex &&
                      item.id === 4 &&
                      orderStatus?.translated_name === 'Documentos Aprovados' &&
                      'Aguardando envio'}
                    {index === activeStepIndex &&
                      orderStatus?.translated_name !== 'Documentos Aprovados' &&
                      orderStatus?.translated_name !== 'Entregue' &&
                      StatusMessage}
                  </p>
                )}
              </div>
            </S.Wrapper>
          )
        })}
      </S.ContentTimeLine>
      <S.spacingDiv isSmallScreen={isSmallScreen}>
        <Heading>
          <span>
            Pacote {PackageCount + 1}
            {PackageDetails[PackageCount]?.id ? `- # ${PackageDetails[PackageCount]?.id}` : ''}
          </span>
        </Heading>
      </S.spacingDiv>
      <ProductList
        isNotEditingFlavor
        isNotEditing
        productList={filteredItems?.length > 0 ? filteredItems : orderDetails?.items}
      />
      <Resume isNotEditing resumeDetails={orderDetails} />
      <div style={{ visibility: isSmallScreen ? 'visible' : 'hidden' }} className="button items-center py-2">
        <Button onClick={() => history.push('/conta?pagina=pedidos-todos')}>Voltar para a lista de pedidos</Button>
      </div>
    </S.ContainerContent>
  )
}

export default OrderDetails
