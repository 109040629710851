/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import PageTitle from 'components/PageTitle'
import useMediaQuery from 'hooks/useMediaQuery'
import useSignedUser from 'hooks/useSignedUser'
import HelloMessage from 'pages/Account/components/template/AccountSidePanel/components/HelloMessage'
import {
  PATIENT_LINKS_DESCRIPTIONS,
  PATIENT_SIDE_PANEL
} from 'pages/Account/components/template/AccountSidePanel/constants/patient'
import {
  CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL,
  PROFESSIONAL_LINKS_DESCRIPTIONS,
  PROFESSIONAL_SIDE_PANEL
} from 'pages/Account/components/template/AccountSidePanel/constants/professional'
import { useEffect, useMemo, useState } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import When from 'utils/when'
import Banner from './components/Banner'
import CollapsableMenus from './components/CollapsableMenus'
import PathSelectorButton from './components/PathSelectorButton'
import * as Styles from './styles'
import { BANNER_CRM_CRO, BANNER_PROFESSIONAL, PATIENT_BANNERS } from './bannersDataMock'
import ImageModal from '@cannect/assets/img/curatorship/[CNT] Pop up - Curadoria Técnica.png'
import { useHistory } from 'react-router'
import { Modal } from 'antd'
import { set } from 'lodash'

function UserPanel() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { signedUser } = useSignedUser()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const history = useHistory()

  const isCRMOrCRO = useMemo(() => {
    if (
      signedUser?.prescriber?.council === 'CRO' ||
      signedUser?.prescriber?.council === 'CRM' ||
      signedUser?.prescriber?.council === 'RMS'
    ) {
      return true
    }
    return false
  }, [signedUser])

  useEffect(() => {
    if (signedUser?.prescriber?.id) {
      setModalIsOpen(true)
    }
  }, [signedUser])

  const profissionalSidePanel = isCRMOrCRO ? CRO_AND_CRM_PROFESSIONAL_SIDE_PANEL : PROFESSIONAL_SIDE_PANEL

  const DataBanners = isCRMOrCRO ? BANNER_CRM_CRO : BANNER_PROFESSIONAL

  return (
    <Styles.UserPanelContainer>
      <PageTitle>Painel do usuário</PageTitle>

      <Styles.PathsWrapper>
        {!isMobile ? (
          signedUser?.registration_type === 1 ? (
            PATIENT_SIDE_PANEL.map((item) => (
              <PathSelectorButton
                to={item.title.to ?? item.subItems?.[0].to ?? '/conta'}
                icon={item.title.icon}
                title={item.title.label} // @ts-ignore
                description={PATIENT_LINKS_DESCRIPTIONS[item.title.label]}
                enabled={item.title.enabled}
              />
            ))
          ) : (
            profissionalSidePanel.map((item) => (
              <PathSelectorButton
                to={item.title.to ?? item.subItems?.[0].to ?? '/conta'}
                icon={item.title.icon}
                title={item.title.label} // @ts-ignore
                description={PROFESSIONAL_LINKS_DESCRIPTIONS[item.title.label]}
                enabled={item.title.enabled}
              />
            ))
          )
        ) : (
          <>
            <HelloMessage />
            <CollapsableMenus />
          </>
        )}
      </Styles.PathsWrapper>

      <When expr={!isMobile}>
        <When expr={!(signedUser?.registration_type === 1)}>
          <PageTitle>Novidades Cannect</PageTitle>
          <Banner data={DataBanners} />
        </When>
      </When>

      {/* <When expr={signedUser?.registration_type === 1}>
        <PageTitle>Novidades Cannect</PageTitle>
        <Banner data={PATIENT_BANNERS} />
      </When> */}
      <Modal
        open={modalIsOpen}
        onOk={() => setModalIsOpen(false)}
        onCancel={() => setModalIsOpen(false)}
        width={{
          xs: '90%',
          sm: '80%',
          md: '70%',
          lg: '60%',
          xl: '50%',
          xxl: '40%'
        }}
        footer={null}>
        <button
          onClick={() => {
            history.push('/curadoria')
          }}
          style={{ border: 'none', background: 'none', padding: 0, marginTop: 35 }}>
          <img src={ImageModal} alt="modal" style={{ cursor: 'pointer' }} />
        </button>
      </Modal>
    </Styles.UserPanelContainer>
  )
}

export default UserPanel
