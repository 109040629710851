import { useState, useEffect } from 'react'
import api from '../../services/api'

import {
  SUPPLIER_FILTERS,
  fetchExtractionMethod,
  fetchGMP,
  fetchProductFilters,
  fetchRoutesOfAdministration,
  fetchTypeStudies,
  fetchVeiculos
} from './helpers'

export default () => {
  const [isFetching, setIsFetching] = useState(true)
  const [filter, setFilter] = useState<any>([])
  const [dataPathologies, setDataPathologies] = useState<any>([])
  const [keyFilter, setKeyFilter] = useState<any>([])

  const signedUser = JSON.parse(localStorage.getItem('@CANNECT_USER') || '{}')

  const getFiltersOther = (filters: any) => {
    const keyFilter = {} as any
    // eslint-disable-next-line array-callback-return
    Object.entries(filters).map(([key, value]: any) => {
      keyFilter[key] = value?.map((items: any) => {
        return { ...items, checked: items?.checked || false, disabled: false }
      })
    })

    setFilter(keyFilter)
    setKeyFilter(Object.keys(keyFilter))
    return keyFilter
  }

  const getFilters = async () => {
    try {
      setIsFetching(true)
      const [shapes, routeofadministrations, filter, veiculo, extraction_method, gmp] = await Promise.all([
        fetchTypeStudies(),
        fetchRoutesOfAdministration(),
        fetchProductFilters(),
        fetchVeiculos(),
        fetchExtractionMethod(),
        fetchGMP()
      ])

      getFiltersOther(
        signedUser.prescriber_id
          ? {
              supplier_id: SUPPLIER_FILTERS,
              gmp,
              veiculo,
              extraction_method,
              ...filter,
              shapes,
              routeofadministrations
            }
          : {
              supplier_id: SUPPLIER_FILTERS,
              ...filter,
              shapes,
              routeofadministrations
            }
      )
    } catch (e) {
      console.log(e)
    } finally {
      setIsFetching(false)
    }
  }

  const pathologiesRequest = async () => {
    try {
      const {
        data: { pathologies }
      } = await api.get(`/pathologies`)
      setDataPathologies(pathologies)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    pathologiesRequest()
  }, [])

  return { filters: false, filter, isFetching, keyFilter, dataPathologies, pathologiesRequest, getFilters }
}
