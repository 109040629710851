import { theme } from 'design-cannect'
import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

interface AccordionProps {
  isOpen: boolean
}

export const Container = styled.div<AccordionProps>`
  margin-top: 20px;
  width: 100%;
  max-width: 287px;
  min-width: 287px;
  @media (${MOBILE_WIDTH}) {
    width: 100%;
    max-width: unset;
  }

  @media (${MOBILE_WIDTH}) {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }

  ${media.tablet`
    max-width: unset;
    min-width: unset;
  `}

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-right: ${theme.spacing.space2};
    font-size: ${theme.fontSize.md};

    padding: 0 16px;

    &:hover:not(:disabled) {
      transform: scale(1.1);
    }

    &:active:not(:disabled) {
      transform: scale(1.02);
      opacity: 0.8;
    }

    svg {
      color: ${({ isOpen }) => (!isOpen ? '#6DA692' : 'white')};
      font-size: 22px;
      transition: transform 0.3s;
      transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    }
  }

  @media ${MOBILE_WIDTH} {
    button {
      /* margin-left: 16px; */
    }

    .header {
      padding: 0 16px;
    }
  }

  @media ${TABLET_WIDTH} {
    min-width: unset;
  }
`
export const HeaderAccordionFilter = styled.div<any>`
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  height: 54px;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  background: ${({ isOpen }) => isOpen && '#464F31'};
  justify-content: space-between;
  transition: transform 0.2s;
  cursor: pointer;
  padding-left: 24px;
`

export const Wrapper = styled.div<AccordionProps>`
  overflow: hidden;
  padding: 0 8px 0 26px;
  padding-top: ${({ isOpen }) => (isOpen ? '16px' : '0px')};
  width: 100%;
  position: relative;
  opacity: ${({ isOpen }) => (!isOpen ? '0' : '1')};
  max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  overflow-y: ${({ isOpen }) => (isOpen ? 'auto' : 'hidden')};
  transition-duration: 0.6s;
  transition-property: opacity, max-height, padding-top, color;
  background-color: ${({ isOpen }) => isOpen && '#ececec'};
  border-radius: 0 0 8px 8px;

  color: #00000000;
  ::-webkit-scrollbar {
    width: 14px;
    height: 8px;
  }
  :-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 7px;
    box-shadow: inset 0 0 0 6px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  :hover {
    color: #d9d9d9;
  }

  .label {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    color: ${theme.colors.darkGrey};
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
export const ToggleButton = styled.button`
  border: none;
  background-color: transparent;
  float: none !important;
  margin: 0 !important;
  padding: 0;
  cursor: pointer;
`
export const LabelText = styled.span<{ isOpen: boolean }>`
  font-size: 14px;
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  color: ${({ isOpen }) => (isOpen ? '#fff' : theme.colors.darkGrey)};
`

export const LabelSubText = styled.span<{ isOpen: boolean }>`
  font-size: 10px;
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  color: ${({ isOpen }) => (isOpen ? '#fff' : theme.colors.darkGrey)};
`
