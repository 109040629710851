import { Button, Modal } from 'antd'
import { useHistory } from 'react-router'
import * as S from './styles'

interface SelectFlavorModalProps {
  open: boolean
  handleCancel: () => void

  message: string
  urlRedirect: string
  onClick?: () => void
  learnMore: boolean
}

export function ProductModalDisabled({
  open,
  handleCancel,
  message,
  urlRedirect,
  onClick,
  learnMore
}: SelectFlavorModalProps) {
  const redirectLink = (link: string) => {
    window.open(link, '_blank', 'noopener')
  }

  const handleClose = () => {
    handleCancel()
  }
  const history = useHistory()
  const isAssistantPage = history.location.pathname.split('/')[1] === 'produtos_assistente'
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      centered
      footer={null}
      zIndex={1005}
      width={open ? '90%' : 850}
      style={{ maxWidth: 850 }}>
      <S.Wrapper>
        <S.Title>Cannect informa</S.Title>

        <S.ContentText>{message}</S.ContentText>

        <S.ActionsContent>
          {learnMore ? (
            <Button
              style={{ width: '200px', height: '40px', backgroundColor: '#464F31', color: 'white' }}
              onClick={() => redirectLink(urlRedirect)}>
              Clique para saber mais!
            </Button>
          ) : (
            <>
              <Button
                style={{ width: '200px', height: '40px', backgroundColor: '#464F31', color: 'white' }}
                onClick={() => redirectLink(urlRedirect)}>
                Quero suporte
              </Button>
              <Button
                style={{
                  width: '200px',
                  height: '40px',
                  backgroundColor: '#fff',
                  color: '#464F31',
                  border: '2px solid #464F31'
                }}
                onClick={() => {
                  handleClose()
                  if (onClick) onClick()
                }}>
                {isAssistantPage ? 'Seguir com minha prescrição' : 'Seguir com meu pedido'}
              </Button>
            </>
          )}
        </S.ActionsContent>
      </S.Wrapper>
    </Modal>
  )
}
