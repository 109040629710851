/* eslint-disable no-nested-ternary */
import { ProductModalDisabled } from '@cannect/new-components/ProductModalDisabled'
import { ProductModalDisabledNutraceutico } from '@cannect/new-components/ProductModalDisabledNutraceutico'
import { cannectDefaultProduct, cannectDefaultProductNoTarg } from 'assets/img'
import useMediaQuery from 'hooks/useMediaQuery'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useSignedUser from 'hooks/useSignedUser'
import { useTokenApi } from 'hooks/useTokenApi'
import { SelectFlavorModal } from 'new-components/SelectFlavorModal'
import { MinusCircle, Receipt } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { BiLoaderCircle } from 'react-icons/bi'
import { FiLock, FiShoppingCart } from 'react-icons/fi'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'
import When from '../../../utils/when'
import PromotionalPrice from '../PromotionalPrice'
import * as Styles from './styles'
import { Texts, RedirectUrls } from '../../../constants/texts'

export interface ProductCardProps {
  name?: string
  translated_name?: string
  isFavorite?: boolean
  real_price?: number
  image?: string
  handleFavorite?: () => void
  id: number
  supplier_id?: number
  importProduct?: (val: any) => void
  handleLogin?: any
  promotional?: any
  product?: any
  isAssistant?: boolean
  prescribedProduct?: boolean
  stock?: number
  isRelatedProduct?: boolean
  isSupllements?: boolean
  setOpenModalSupplements?: any
}

export default function ProductCard({
  name,
  translated_name,
  isFavorite,
  handleFavorite = () => null,
  real_price,
  image,
  id,
  supplier_id,
  importProduct = () => null,
  handleLogin = () => null,
  promotional,
  product,
  isAssistant,
  prescribedProduct,
  isRelatedProduct,
  stock,
  isSupllements = false,
  setOpenModalSupplements
}: ProductCardProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { signedUser } = useSignedUser()
  const { tokenApi } = useTokenApi()
  const { addToCart, loadingCupon, lastOrderCreated, AddToCartByFlavor } = usePrepareCart()
  const location = useLocation()
  const [products, setProducts] = useState<any[]>([])
  const [flavorModal, setFlavorModal] = useState(false)
  const [loadingOnSave, setLoadingOnSave] = useState(false)
  const [defaultSelectedFlavor, setDefaultSelectedFlavor] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [hasMultipleFlavors, setHasMultipleFlavors] = useState(false)
  const [shippingText, setShippingText] = useState('')
  const [stockValue, setstockValue] = useState<any>(0)
  const [nutraceuticosModal, setNutraceuticosModal] = useState(false)
  const [anvisaModal, setAnvisaModal] = useState<boolean>(false)
  const [anvisaModalMessage, setAnvisaModalMessage] = useState<string>('')
  const [modalUrlClickButton, setModalUrlClickButton] = useState<string>('')
  const [learnMore, setLearnMore] = useState<boolean>(false)

  const class_id = product?.class_id
  const brand_id = product?.brand_id

  useEffect(() => {
    if (lastOrderCreated?.items) {
      const essentialProductsData = products.map((product: any) => ({
        id: product.product.id,
        quantity: product.quantity
      }))
      setProducts(essentialProductsData)
    }
  }, [lastOrderCreated])

  useEffect(() => {
    setLoading(loadingCupon)
  }, [loadingCupon])

  useEffect(() => {
    if (stock !== undefined) {
      setstockValue(stock)
    }
  })

  useEffect(() => {
    const checkMultipleFlavors = async () => {
      let shippingText = ''

      if (product?.class?.id === 6 || product?.brand_id === 108) {
        setShippingText(shippingText)
        return
      }

      const { data } = await api.get(`/get_product_flavors/${id}`)
      const hasFlavors = data?.success && Array.isArray(data.productFlavors) && data.productFlavors.length > 0

      const isImported = product.supplier_id !== 18 && product.supplier_id !== 27

      const isOutOfStock = product.stock <= 0

      if (hasFlavors) {
        setHasMultipleFlavors(true)
      } else {
        setHasMultipleFlavors(false)
      }

      if (isImported) {
        shippingText = 'Importado: entrega em até 15 dias úteis.'
      }

      if (isOutOfStock && isImported) {
        shippingText = 'Possível maior tempo de entrega'
      }

      setShippingText(shippingText)
    }

    checkMultipleFlavors()
  }, [id, product])

  const handleSaveFlavor = async (items: any[]) => {
    setLoadingOnSave(true)
    const itemArray = Array.isArray(items) ? items : [items]

    if (isAssistant) {
      await importProduct(itemArray)
    } else {
      await AddToCartByFlavor(itemArray)
    }
  }

  const handleOk = () => {
    setLoadingOnSave(false)
    setFlavorModal(false)
  }

  const getProductDetails = () => {
    if (isSupllements) {
      window.open(`/detalhes-produtos_suplementos/${id}`, '_blank')
    } else {
      const normalizeText = (text: string) => text.trim().replace(/\s+/g, '-').replace(/-+/g, '-')

      const brandName = normalizeText(product?.brand?.name || '')
      const productName = normalizeText(product?.name || '')

      window.open(`/detalhes-produtos/${id}`, '_blank')
    }
  }

  const handleFavoriteCard = () => {
    handleFavorite()
    try {
      api.post(`/product/favorite/${id}`)
    } catch (e) {
      toast.error('Erro ao favoritar')
      handleFavorite()
    }

    const msg = isFavorite ? 'Produto removido dos favoritos' : 'Produto adicionado aos favoritos'
    toast.success(msg)
  }

  const textAssistant = prescribedProduct ? 'Selecionado' : 'Prescrever'

  const infoBySupplier = {
    shippingText,
    buttonText: supplier_id === 18 || supplier_id === 27 ? 'Comprar' : 'Importar',
    defaultImage: supplier_id === 18 || supplier_id === 27 ? cannectDefaultProduct : cannectDefaultProductNoTarg
  }

  const handleAddToCart = async (id: number) => {
    if (isSupllements) {
      setOpenModalSupplements(true)
      return
    }

    const brandModals: any = {
      99: {
        message: Texts.PURE_JAMAICA_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      109: {
        message: Texts.NEX_BIOPHARMA_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      19: {
        message: Texts.HEMP_MEDS_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      113: {
        message: Texts.SENSIA_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      114: {
        message: Texts.GREENS_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      61: {
        message: Texts.FLOWER_MED_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      124: {
        message: Texts.EXTRACTOS_DEL_SUR_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      108: {
        message: Texts.ENDOGEN_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      91: {
        message: Texts.BIOCASE_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      },
      93: {
        message: Texts.BEHEMP_MESSAGE,
        url: RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      }
    }

    const listProductDisabledCannaRiver = [
      15561, 14721, 14887, 14885, 14867, 14868, 14866, 14393, 14369, 14844, 14843, 14842
    ]

    const listProductAttendant = [15668]

    const handleShowModal = (message: string, url: string, learnMore = false) => {
      setFlavorModal(true)
      setAnvisaModal(true)
      setModalUrlClickButton(url)
      setLearnMore(learnMore)
      setAnvisaModalMessage(message)
    }

    if (listProductDisabledCannaRiver.includes(product?.id)) {
      handleShowModal(
        'Atenção, cliente! Neste momento, os produtos da linha Canna River podem ter um prazo de entrega maior que o habitual. Você pode adicionar o produto ao carrinho ou entrar em contato com o nosso time de vendas para mais informações.',
        RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION
      )
      return
    }

    if (listProductAttendant.includes(product?.id)) {
      handleShowModal(
        'No momento, este produto está disponível para importação apenas com nossa equipe de atendimento.',
        RedirectUrls.WHATSAPP_SCHEDULE_CONSULTATION,
        true
      )
      return
    }

    const brand = brandModals[product?.brand?.id]
    if (brand) {
      handleShowModal(brand.message, brand.url)
      return
    }

    if (hasMultipleFlavors) {
      setAnvisaModal(false)
      setFlavorModal(true)
      setDefaultSelectedFlavor(product)
      setLoading(false)
      return
    }

    setLoading(true)
    try {
      if (isAssistant) {
        await importProduct(product)
      } else {
        await addToCart(id, 1, product.class_id, product.brand_id)
      }
    } catch (e) {
      console.error('Erro ao adicionar ao carrinho: ', e)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseFlavorModal = () => {
    setFlavorModal(false)
  }

  const handleCloseAnvisa = () => {
    setAnvisaModal(false)
  }

  const isPatientUserType = signedUser?.registration_type === 1
  const selectedImage = isPatientUserType && !(product?.class_id === 6) ? infoBySupplier.defaultImage : image

  return (
    <Styles.Wrapper withAuth={!!tokenApi}>
      <Styles.DetailsContent>
        <When expr={!!tokenApi}>
          <Styles.FavoriteBox onClick={handleFavoriteCard}>
            {isFavorite ? <AiFillHeart color={temporaryTheme.colors.red} /> : <AiOutlineHeart color="#858585" />}
          </Styles.FavoriteBox>
        </When>

        <Styles.ImageContainer>
          <Styles.ImageBox>
            <Styles.Image src={selectedImage} alt={`imagem do produto ${name}`} />

            <Styles.MoreInfo>
              <Styles.ButtonMoreInfo onClick={getProductDetails}>detalhes</Styles.ButtonMoreInfo>
            </Styles.MoreInfo>
          </Styles.ImageBox>
        </Styles.ImageContainer>

        <Styles.TitleProductBox onClick={getProductDetails}>
          <Styles.TitleProduct>{translated_name || name}</Styles.TitleProduct>

          <When expr={(!!tokenApi || isSupllements) && isMobile && !isRelatedProduct}>
            <Styles.PriceBox>
              {/* <Styles.Price>
                {formatPrice({
                  value: validPromotion ? promotional?.promotional_price : real_price,
                  currencyDisplay: 'symbol',
                  currencyStyle: 'BRL',
                  type: 'pr-br'
                })}
              </Styles.Price> */}
              <PromotionalPrice
                new_price={promotional?.promotional_price || product?.real_price}
                old_price={promotional?.regular_price}
                discount={promotional?.discount_value}
                discount_type={promotional?.discount_type}
              />
            </Styles.PriceBox>
          </When>
        </Styles.TitleProductBox>
      </Styles.DetailsContent>

      <Styles.ProductSabor
        bgColor={supplier_id !== 18 && supplier_id !== 27 ? 'orange' : 'green'}
        fontColor={supplier_id !== 18 && supplier_id !== 27 ? 'orange' : 'green'}>
        {!!loading && (
          <div className="load-box">
            <BiLoaderCircle />
          </div>
        )}
        {supplier_id !== 27 ? 'Necessário prescrição' : 'Frete grátis'}
      </Styles.ProductSabor>

      <Styles.ActionsContent>
        <When expr={(!!tokenApi || isSupllements) && !isMobile && !isRelatedProduct}>
          <Styles.PriceBox>
            {/* <Styles.Price>
              {formatPrice({
                value: validPromotion ? promotional?.promotional_price : real_price,
                currencyDisplay: 'symbol',
                currencyStyle: 'BRL',
                type: 'pr-br'
              })}
            </Styles.Price> */}
            <PromotionalPrice
              new_price={promotional?.promotional_price || product?.real_price}
              old_price={promotional?.regular_price}
              discount={promotional?.discount_value}
              discount_type={promotional?.discount_type}
            />
          </Styles.PriceBox>
        </When>

        <Styles.ActionBox>
          {tokenApi || isSupllements ? (
            <div className="acc">
              {isAssistant ? (
                <Styles.ButtonAssistant
                  onClick={() => handleAddToCart(id)}
                  isLoading={loading}
                  prescribedProduct={!!prescribedProduct}>
                  {!!loading && (
                    <div className="load-box">
                      <BiLoaderCircle />
                    </div>
                  )}
                  {textAssistant}

                  {prescribedProduct ? <MinusCircle size={18} /> : <Receipt size={18} />}
                </Styles.ButtonAssistant>
              ) : (
                <Styles.ButtonProduct
                  onClick={() => handleAddToCart(id)}
                  isLoading={loading}
                  className="button-action_product"
                  bgColor={supplier_id === 18 || (class_id === 6 && brand_id === 108) ? 'green' : 'orange'}>
                  {!!loading && (
                    <div className="load-box">
                      <BiLoaderCircle />
                    </div>
                  )}
                  {infoBySupplier.buttonText} {isSupllements ? <FiLock /> : <FiShoppingCart />}
                </Styles.ButtonProduct>
              )}
            </div>
          ) : (
            <Styles.ButtonProduct onClick={handleLogin}>Faça login para ver mais</Styles.ButtonProduct>
          )}
        </Styles.ActionBox>

        <When expr={!!tokenApi || isSupllements}>
          <Styles.DetailsTrack isEmpty={!shippingText}>{shippingText || ' '}</Styles.DetailsTrack>
        </When>
      </Styles.ActionsContent>

      {/* <SelectFlavorModal
          open={flavorModal}
          handleCancel={handleCloseFlavorModal}
          handleOk={() => handleOk()}
          isAssistant={isAssistant as boolean}
          defaultSelected={defaultSelectedFlavor}
          onSave={(items) => handleSaveFlavor(items)}
          loadingOnSave={loadingOnSave}
          isAuthenticated={!!tokenApi}
          imageSelector={(isAuthenticated, defaultSelected) =>
            isAuthenticated
              ? (isPatientUserType ? infoBySupplier.defaultImage : defaultSelected.image) ||
              'path/to/authenticated/default/image.jpg'
              : 'path/to/public/default/image.jpg'
          }
        /> */}

      <ProductModalDisabledNutraceutico
        open={nutraceuticosModal}
        handleCancel={() => setNutraceuticosModal(false)}
        isAuthenticated={!!tokenApi}
      />
      {anvisaModal ? (
        <ProductModalDisabled
          open={anvisaModal}
          learnMore={learnMore}
          message={anvisaModalMessage}
          urlRedirect={modalUrlClickButton}
          handleCancel={handleCloseAnvisa}
          isAuthenticated={!!tokenApi}
          onClick={async () => {
            if (hasMultipleFlavors) {
              setAnvisaModal(false)
              setFlavorModal(true)
              setDefaultSelectedFlavor(product)
              setLoading(false)
            } else {
              try {
                if (isAssistant) {
                  await importProduct(product)
                } else {
                  await addToCart(id)
                }
              } catch (e) {
                console.log('Erro na busca de sabores: ', e)
              } finally {
                setLoading(false)
              }
            }
          }}
        />
      ) : (
        hasMultipleFlavors && (
          <SelectFlavorModal
            open={flavorModal}
            handleCancel={handleCloseFlavorModal}
            handleOk={() => handleOk()}
            isAssistant={isAssistant as boolean}
            defaultSelected={defaultSelectedFlavor}
            onSave={(items) => handleSaveFlavor(items)}
            loadingOnSave={loadingOnSave}
            isAuthenticated={!!tokenApi}
            imageSelector={(isAuthenticated, defaultSelected) =>
              isAuthenticated
                ? (isPatientUserType ? infoBySupplier.defaultImage : defaultSelected.image) ||
                  'path/to/authenticated/default/image.jpg'
                : 'path/to/public/default/image.jpg'
            }
          />
        )
      )}
    </Styles.Wrapper>
  )
}
