import { Button } from 'design-cannect'
import { forEach } from 'lodash'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import * as queryString from 'querystring'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFilterProduct } from '../../../hooks/FilterProductContext'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { FILTER_NAME_PRODUCTS, FILTER_NAME_PRODUCTS_CURATORSHIP, MOBILE_WIDTH } from '../../../utils/constants'
import { transformFilterProductsUrl } from '../../../utils/formatedParamsUrl'
import AccordionFilter from '../../AccordionFilter'
import Checkbox from '../../Checkbox'
import Loading from '../../Loading'
import * as Styles from './styles'

function NewFilter({
  reset,
  setReset,
  clearFilters,
  validateClearFilters,
  pathFilter = '/produtos/1',
  isContentBlocked = false,
  setModalLogin
}: any) {
  const { inputValue, setInputValue, isFetching, keyFilter, filter } = useFilterProduct()

  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState<any>(true)

  const validateFilterProductsUrl = () => {
    const listFilter = {} as any
    const keyParamsSearchUrl = transformFilterProductsUrl(location.search) as any
    forEach(keyFilter, (key) => {
      const newFilter = filter[key]
      listFilter[key] = newFilter.map((items: any) => {
        return {
          ...items,
          checked: keyParamsSearchUrl[key]?.includes(
            items?.id?.toString() || keyParamsSearchUrl[key] === items?.id?.toString()
          )
        }
      })
    })

    return listFilter
  }

  useEffect(() => {
    if (reset) {
      setLoading(true)
      setInputValue(validateFilterProductsUrl())
      setLoading(false)
      setReset(false)
    }
  }, [reset])
  useEffect(() => {
    if (!isFetching && keyFilter) {
      setLoading(true)
      setInputValue(validateFilterProductsUrl())
      setLoading(false)
    }
  }, [isFetching, keyFilter])

  const dataKeysFiltersValidate = (newInput: any) => {
    const inputValuesFilter = {} as any
    forEach(keyFilter, (key) => {
      const newFilter = newInput[key]
      const inputValuesFilterTransform = newFilter
        .filter((item: any) => {
          return item.checked
        })
        ?.map((items: any) => items?.id)
      if (inputValuesFilterTransform.length > 0) {
        inputValuesFilter[key] = inputValuesFilterTransform
      }
    })
    const search = queryString.stringify(inputValuesFilter)
    history.push({ pathname: pathFilter, search })
  }

  const handleChange = (nameKey: any, item: any) => {
    const newFilter = inputValue[nameKey]
    const newInputValue = newFilter?.map((input: any) => {
      if (input?.id === item?.id) {
        return { ...input, checked: !input.checked }
      }
      return input
    })
    const newInput = { ...inputValue, [nameKey]: newInputValue }
    console.log(newInput)
    setInputValue(newInput)
    dataKeysFiltersValidate(newInput)
  }

  const handleCheck = (nameKey: any) => {
    return (
      <div>
        {inputValue[nameKey]?.map((item: any) => {
          return (
            <Styles.CheckBoxContainer withDescription={!!item?.description} key={item.id}>
              <Checkbox
                className="checkbox-filter"
                checked={item?.checked}
                disabled={item?.disabled || false}
                label={
                  <span style={{ position: 'relative' }}>
                    {item?.name || 'name'}
                    {nameKey === 'supplier_id' && item?.name === 'Suplementos' && (
                      <Styles.NewBadge>NOVO!</Styles.NewBadge>
                    )}
                  </span>
                }
                onClick={() => handleChange(nameKey, item)}
              />
              {nameKey === 'supplier_id' && (
                <Styles.TextBox>
                  <TextDanger text={item?.description} fontWeight={500} fontSize="12px" />
                </Styles.TextBox>
              )}
            </Styles.CheckBoxContainer>
          )
        })}
      </div>
    )
  }

  const openModalLogin = () => {
    setModalLogin(true)
  }

  const signedUser = JSON.parse(localStorage.getItem('@CANNECT_USER') || '{}')

  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <div style={{ position: 'relative' }}>
      {isContentBlocked && !loading && (
        <Styles.Overlay>
          <Styles.OverlayContainer>
            <Styles.OverlayMessage>
              Para continuar navegando e acessar mais informações, faça login ou cadastre-se.
            </Styles.OverlayMessage>
            <Styles.OverlayMessageButton onClick={openModalLogin}>Login/Cadastro</Styles.OverlayMessageButton>
          </Styles.OverlayContainer>
        </Styles.Overlay>
      )}

      <Styles.ContentWrapper isBlocked={isContentBlocked}>
        {!(!isFetching && !loading) ? (
          <Loading loading />
        ) : (
          keyFilter?.map((nameKey: any, index: number) => {
            const defaultOpen = nameKey === 'supplier_id'
            if (nameKey === 'routeofadministrations' || nameKey === 'shapes') {
              return null
            }
            return (
              <AccordionFilter
                key={index}
                title={
                  !signedUser?.prescriber_id ? FILTER_NAME_PRODUCTS[nameKey] : FILTER_NAME_PRODUCTS_CURATORSHIP[nameKey]
                }
                subTitle={nameKey === 'gmp' ? 'Boas praticas de fabricação' : ''}
                isNew={nameKey === 'gmp' || nameKey === 'veiculo' || nameKey === 'extraction_method'}
                defaultOpen={defaultOpen}>
                {handleCheck(nameKey)}
              </AccordionFilter>
            )
          })
        )}

        {isMobile && validateClearFilters && (
          <div style={{ paddingTop: '10px' }}>
            <Button outlined onClick={clearFilters}>
              Limpar Filtros
            </Button>
          </div>
        )}
      </Styles.ContentWrapper>
    </div>
  )
}

export default NewFilter
