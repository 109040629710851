import React, { useState } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import * as Styles from './styles'

interface AccordionProps {
  title?: any
  children?: React.ReactNode
  defaultOpen?: boolean
  subTitle?: string
  isNew?: boolean
}
function AccordionFilter({ title, children, defaultOpen = false, subTitle, isNew }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <Styles.Container isOpen={isOpen}>
      <Styles.HeaderAccordionFilter isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div>
          <div>
            <Styles.LabelText isOpen={isOpen}>{title}</Styles.LabelText>
          </div>
          {subTitle && (
            <div>
              <Styles.LabelSubText isOpen={isOpen}>{subTitle}</Styles.LabelSubText>
            </div>
          )}
        </div>
        <div className="flex flex-col items-end">
          {isNew && (
            <div className="mr-4">
              <span className="mr-4 text-left font-semibold text-warning-600">Novidade</span>
            </div>
          )}
          <div>
            <Styles.ToggleButton type="button">{isOpen ? <FiMinusCircle /> : <FiPlusCircle />}</Styles.ToggleButton>
          </div>
        </div>
      </Styles.HeaderAccordionFilter>
      <Styles.Wrapper isOpen={isOpen}>
        <div>{children}</div>
      </Styles.Wrapper>
    </Styles.Container>
  )
}

export default AccordionFilter
