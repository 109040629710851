import BreadCrumb from 'components/BreadCrumb'
import BeginCheckout from 'components/Home/BeginCheckout'
import ModalHome from 'components/Home/ModalHome'
import LoadingContainer from 'components/LoadingContainer'
import { IStrapiTratamento } from 'dtos/Tratamento'
import useMediaQuery from 'hooks/useMediaQuery'

import RelatedProduct from '@cannect/pages/ProductDetailsV2/RelatedProduct'
import BadgeTreatmentType from 'new-components/TreatmentsComponents/BadgeTreatmentType'
import ResumeTreatments from 'new-components/TreatmentsComponents/ResumeTreatments'
import Symptoms from 'new-components/TreatmentsComponents/Symptoms'
import TreatmentSymptom from 'new-components/TreatmentsComponents/TreatmentSymptoms'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getTratamento } from 'usecases/tratamentos'
import { MOBILE_WIDTH } from 'utils/constants'
import { BadgesMedicalTreatments, BadgesTreatmentsWellBeing } from '../mockTreatment'
import * as Styles from './styles'

function Treatment() {
  const { slug } = useParams() as any
  const categoriesNavigator = [...BadgesMedicalTreatments, ...BadgesTreatmentsWellBeing]
  const [treatment, setTreatment] = useState<IStrapiTratamento>({} as IStrapiTratamento)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const redirectLink = (link = 'https://atendimento.cannect.life/atendimento-cannect') => {
    window.open(link, '_blank', 'noopener')
  }

  const getTreatments = async () => {
    setLoading(true)
    try {
      const responseTratamento = (await getTratamento({ slug })) as any
      setTreatment(responseTratamento.data[0])
    } catch (error) {
      toast.error('Erro na busca do tratamento...')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTreatments()
  }, [slug])

  return (
    <Styles.Wrapper>
      <BreadCrumb
        paths={[
          { label: 'Tratamentos', link: '/tratamentos' },
          { label: slug, link: '/tratamentos' }
        ]}
      />

      <Styles.CategoryNavigatorSection>
        {categoriesNavigator.map((item) => (
          <BadgeTreatmentType
            icon={item.icon}
            title={item.title}
            slug={item.slug}
            key={item.id}
            size="small"
            activeCategory={slug === item.type}
          />
        ))}
      </Styles.CategoryNavigatorSection>

      {loading ? (
        <LoadingContainer loading />
      ) : (
        <>
          <Styles.SectionBase className="firstSection">
            <ResumeTreatments
              onClickPrimary={() => redirectLink()}
              onClickSecondary={() => setModal(true)}
              categoryTitle={treatment?.attributes?.title}
              categoryIcon={treatment?.attributes?.icon?.data?.attributes?.url}
              title={treatment?.attributes?.title}
              text={treatment?.attributes?.sobre}
              image={treatment?.attributes?.image.data.attributes.url}
            />
          </Styles.SectionBase>

          <Styles.SectionBase>
            <Symptoms text={treatment?.attributes?.sintomas} />
          </Styles.SectionBase>

          <Styles.SectionBase>
            <TreatmentSymptom
              text={treatment?.attributes?.tratamento}
              onClickPrimary={() => redirectLink()}
              onClickSecondary={() => setModal(true)}
            />
          </Styles.SectionBase>

          <RelatedProduct filters={`category_id=${treatment?.id}`} limit={4} />
        </>
      )}

      <ModalHome setOpenModal={setModal} isOpen={modal}>
        <BeginCheckout />
      </ModalHome>
    </Styles.Wrapper>
  )
}

export default Treatment
