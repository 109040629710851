import { Button, Typography } from '@cannect/new-components/atoms'
import cgmpGrauFarmaceuticoImg from '../../assets/img/curatorship/cgmp-grau-farmaceutico.png'
import cgmpGrauNutricionalImg from '../../assets/img/curatorship/cgmp-grau-nutricional.png'
import curatorshipProcessImg from '../../assets/img/curatorship/curatorship-process.png'
import curatorshipSolutionImg from '../../assets/img/curatorship/curatorship-solution.png'
import eugmpImg from '../../assets/img/curatorship/eu-gmp-grau-farmaceutico.png'
import gacpImg from '../../assets/img/curatorship/gacp.png'
import iso91001Img from '../../assets/img/curatorship/iso-9001.png'
import organicoImg from '../../assets/img/curatorship/organico.png'
import veganoImg from '../../assets/img/curatorship/vegano.png'

const processStamps = [
  {
    title: 'cGMP Grau Nutricional:',
    description: 'Garante boas práticas de fabricação para produtos nutricionais.',
    image: cgmpGrauNutricionalImg
  },
  {
    title: 'cGMP Grau Farmacêutico:',
    description: 'Certifica conformidade com normas farmacêuticas internacionais.',
    image: cgmpGrauFarmaceuticoImg
  },
  {
    title: 'EU-GMP Grau Farmacêutico:',
    description: 'Segue as exigências da União Europeia para produtos farmacêuticos.',
    image: eugmpImg
  },
  {
    title: 'GACP:',
    description: 'Assegura boas práticas agrícolas e de colheita para plantas medicinais.',
    image: gacpImg
  },
  {
    title: 'ISO 9001:',
    description: 'Padrão internacional para sistemas de gestão da qualidade.',
    image: iso91001Img
  },
  {
    title: 'Vegano:',
    description: 'Garante que o produto não contém ingredientes de origem animal.',
    image: veganoImg
  },
  {
    title: 'Orgânico:',
    description: 'Certifica que o cultivo seguiu práticas orgânicas sustentáveis.',
    image: organicoImg
  }
]

export const CuratorshipPage = () => {
  return (
    <div className="w-full max-w-full bg-secondary-100">
      <section className="bg-curatorship-hero-mobile bg-cover bg-center bg-no-repeat md:bg-curatorship-hero">
        <div className="container flex min-h-[calc(100dvh-74px)] flex-col-reverse items-center justify-start gap-8 px-4 pb-23 pr-10 md:min-h-[calc(80dvh-106px)] md:flex-row md:justify-between md:px-21 md:py-24">
          <div className="flex flex-col gap-4 md:items-start md:gap-8">
            <Typography.Heading type="headingTwo" weight="semibold" className="leading-[43px] text-primary-200">
              Curadoria Técnica de <br />
              Produtos Cannect
            </Typography.Heading>

            <Typography.Heading
              weight="semibold"
              type="headingSix"
              className="max-w-[468px] text-[20px] text-gray-50 md:leading-[33px]">
              Qualidade, procedência e segurança
              <br /> atestados por quem entende
            </Typography.Heading>
          </div>
        </div>
      </section>

      <section className="container max-w-6xl py-section">
        <Typography.Heading type="headingFour" className="mb-8 text-center text-primary-700 md:mb-16 md:text-4xl">
          Solução para o dia a dia
        </Typography.Heading>

        <div className="flex flex-col md:flex-row md:gap-16">
          <div className="flex max-w-[586px] flex-col gap-4">
            <Typography.Text className="">
              Embora esteja crescendo mundialmente, o mercado da <strong>Cannabis Medicinal </strong>ainda carece de
              critérios claros e padronizados para diferenciar produtos que atendem aos mais altos padrões de qualidade.
              <br />
              <br />
              Isso pode comprometer a confiabilidade do tratamento, e claro, colocar em risco a saúde dos pacientes.
              Porém, a Cannect criou uma solução para este problema: a <strong>Curadoria Técnica de Produtos</strong>!
              <br />
              <br />
              Em linhas gerais, é um processo rigoroso que certifica que apenas marcas de excelência chegarão aos
              pacientes e profissionais de saúde.
              <br />
              <br />
              <strong>A cada novo lote, o produto passa por novas análises</strong>, assegurando que sua performance e
              segurança se mantenham.
            </Typography.Text>
          </div>
          <img
            src={curatorshipSolutionImg}
            alt="Curatorship Solution"
            className="mx-auto mt-8 max-w-[300px] rounded-2xl object-cover md:mt-0 lg:max-w-[546px]"
          />
        </div>
      </section>

      <section className="container max-w-6xl pb-section">
        <Typography.Heading type="headingFour" className="mb-8 text-center text-primary-700 md:mb-16 md:text-4xl">
          A curadoria resolve desafios cruciais:
        </Typography.Heading>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          <div className="!space-y-4 rounded-2xl bg-white p-6 md:px-7 md:py-16">
            <Typography.Text weight="semibold">Decisões embasadas em informações acessíveis.</Typography.Text>
            <Typography.Text>
              Tome decisões mais facilmente, com base em dados importantes como certificações, análises laboratoriais e
              conformidade regulatória.
            </Typography.Text>
          </div>
          <div className="!space-y-4 rounded-2xl bg-white p-6 md:px-7 md:py-16">
            <Typography.Text weight="semibold">Menos variabilidade, mais confiabilidade.</Typography.Text>
            <Typography.Text>
              Quando produtos têm menos discrepância entre lotes, a marca demonstra maior consistência em sua produção.
              E quem valida isso é a Curadoria Cannect.
            </Typography.Text>
          </div>
          <div className="!space-y-4 rounded-2xl bg-white p-6 md:px-7 md:py-16">
            <Typography.Text weight="semibold">Mais segurança gera mais evidência.</Typography.Text>
            <Typography.Text>
              Com base em índices de qualidade rigorosos, nossos filtros apontam aos profissionais os produtos com
              validação mais confiável, permitindo a excelência na prescrição. prescrição.
            </Typography.Text>
          </div>
          <div className="!space-y-4 rounded-2xl bg-white p-6 md:px-7 md:py-16">
            <Typography.Text weight="semibold"> Segurança para médicos e pacientes.</Typography.Text>
            <Typography.Text>
              Protege a prática médica, garantindo que a prescrição esteja ancorada em produtos analisados e
              certificados.
            </Typography.Text>
          </div>
        </div>
      </section>

      <section className="container max-w-6xl pb-section">
        <Typography.Heading type="headingFour" className="!mb-8 text-center text-primary-700 md:!mb-16 md:text-4xl">
          Etapas do processo
        </Typography.Heading>

        <div className="mb-8 flex flex-col gap-8 md:mb-16 md:flex-row md:gap-16">
          <div className="w-full !space-y-8 rounded-2xl bg-white p-8 md:!space-y-16 md:px-16 md:py-22">
            <Typography.Heading type="headingFive" className="text-secondary-600 md:text-3xl">
              1. Análise Documental:
            </Typography.Heading>
            <Typography.Text>
              A marca preenche uma ficha técnica detalhada sobre o produto e envia alguns documentos, incluindo:
            </Typography.Text>

            <ul className="space-y-8 pl-6 text-neutral-800 [&>li]:font-sans [&>li]:text-base">
              <li>Descrição do produto e sua formulação.</li>
              <li>Certificados de Boas Práticas de Fabricação (BPF).</li>
              <li>Certificado de Análise (CoA) de até 2 anos.</li>
              <li>Método de extração: Impacto direto na pureza e estabilidade dos fitocanabinoides.</li>
              <li>
                Preservação dos compostos ativos e minimização dos solventes residuais. Os principais métodos de
                extração são: CO₂ supercrítico, alcoólica tradicional, alcoólica a frio e extração por hidrocarbonetos.
              </li>
            </ul>
          </div>

          <div className="flex w-full flex-col gap-4">
            <div className="flex h-[84px] items-center rounded-2xl bg-white px-4 md:px-8">
              <Typography.Text>
                Também analisamos os selos de certificação internacionalmente reconhecidos. Confira:
              </Typography.Text>
            </div>
            {processStamps.map((stamp) => (
              <div
                key={stamp.title}
                className="flex w-full items-center gap-4 rounded-2xl bg-secondary-200 px-4 py-2 md:px-8 md:py-3">
                <img src={stamp.image} alt="" className="size-15" />
                <Typography.Text>
                  <strong className="!font-bold text-secondary">{stamp.title}</strong> {stamp.description}
                </Typography.Text>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-8 md:flex-row">
          <img
            src={curatorshipProcessImg}
            alt="Curatorship Process"
            className="hidden w-full rounded-2xl object-cover md:block md:w-1/2"
          />

          <div className="flex w-full flex-col justify-center !space-y-6 rounded-2xl bg-white p-8 md:!space-y-8 md:px-16 md:py-22">
            <Typography.Heading type="headingFive" className="text-secondary-600 md:mb-4 md:text-3xl">
              2. Teste Prático e Análise Física:
            </Typography.Heading>
            <Typography.Text>Produtos como óleos passam por uma validação prática, que inclui:</Typography.Text>

            <ul className="space-y-8 pl-6 text-neutral-800 [&>li]:font-sans [&>li]:text-base">
              <li>Análise da embalagem, rótulo, frasco e pipeta.</li>
              <li>Teste de gotas/mL, garantindo que a dosagem seja precisa</li>
            </ul>
            <Typography.Text className="italic" type="captionOne">
              Esse processo é repetido ao menos três vezes para minimizar variações e assegurar a fidedignidade da
              dosagem.
            </Typography.Text>
          </div>
        </div>
      </section>
      <section className="bg-primary-100 py-section">
        <div className="container max-w-6xl">
          <div className="flex flex-col gap-8 md:flex-row md:items-center md:justify-between md:gap-16">
            <div className="!space-y-4">
              <Typography.Heading type="headingFive" className="text-secondary md:text-3xl">
                Organização e responsabilidade
              </Typography.Heading>
              <Typography.Text>
                Antes de integrar qualquer marca ou produto à plataforma, a Cannect realiza uma Curadoria Técnica
                contínua. O processo, conduzido por médicos e farmacêuticos, garante uma análise criteriosa de cada
                lote, unindo visão clínica e técnica.
              </Typography.Text>
            </div>
            <div className="!space-y-4">
              <Typography.Heading type="headingFive" className="text-secondary md:text-3xl">
                Compromisso com segurança
              </Typography.Heading>
              <Typography.Text>
                A Cannect mantém uma reavaliação contínua dos produtos na plataforma, assegurando qualidade alinhada às
                evoluções do mercado e regulamentações. Esse compromisso fortalece a confiança entre profissionais de
                saúde e pacientes, criando um ecossistema mais seguro, transparente e confiável.
              </Typography.Text>
            </div>
            <div className="!space-y-4">
              <Typography.Heading type="headingFive" className="text-secondary md:text-3xl">
                Por que escolher a Cannect?
              </Typography.Heading>
              <Typography.Text>
                A Cannect é se posiciona como referência nacional em qualidade e segurança na Cannabis Medicinal. Com um
                rigoroso padrão de avaliação, a plataforma facilita o acesso a tratamentos seguros, garantindo confiança
                para médicos, cirurgiões-dentistas e pacientes.
              </Typography.Text>
            </div>
          </div>
        </div>
      </section>
      <section className="container max-w-6xl py-section">
        <Typography.Heading type="headingFour" className="mb-8 text-center text-primary-700 md:mb-16 md:text-4xl">
          Quer saber mais?
        </Typography.Heading>

        <div className="flex flex-col gap-8 md:flex-row lg:gap-24">
          <div className="flex w-full flex-col gap-4 rounded-2xl bg-white px-5 py-8 md:p-16">
            <Typography.Heading type="headingFive" className="text-secondary md:text-3xl">
              Profissionais de saúde
            </Typography.Heading>
            <Typography.Text>
              Se você deseja entender como a Curadoria Técnica da Cannect apoia a segurança das suas prescrições, entre
              em contato com nosso time de Relacionamento Médico
            </Typography.Text>
            <Button
              className="mt-6 w-fit"
              onClick={() =>
                window.open(
                  'https://api.whatsapp.com/send?phone=5511992208271&text=Ol%C3%A1%2C%20gostaria%20de%20entender%20um%20pouco%20mais%20sobre%20a%20Curadoria%20T%C3%A9cnica%20de%20Produtos%20da%20Cannect.',
                  '_blank'
                )
              }>
              Clique aqui
            </Button>
          </div>
          <div className="flex w-full flex-col gap-4 rounded-2xl bg-white px-5 py-8 md:p-16">
            <Typography.Heading type="headingFive" className="text-secondary md:text-3xl">
              Marcas e farmacêuticas
            </Typography.Heading>
            <Typography.Text>
              Se você é representante de uma marca que ainda não está em nosso portfólio, entre em contato com o nosso
              time de Relacionamento com Marcas.
            </Typography.Text>
            <Button className="mt-6 w-fit" onClick={() => window.open('mailto:marcas@cannect.com.br')}>
              Clique aqui
            </Button>
          </div>
        </div>
        <Typography.Text className="mx-auto mt-8 text-center md:mt-16">
          Estamos prontos para esclarecer dúvidas e construir, juntos, um mercado de Cannabis <br />
          Medicinal mais seguro, transparente e de qualidade.
        </Typography.Text>
      </section>
    </div>
  )
}
