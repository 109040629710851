import useSignedUser from 'hooks/useSignedUser'
import { SelectOption } from 'new-components/MultiSelect'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'
import { clearMask, formatBirthdayByApi, formatCpf, formatPhone, getBrazilianFormatDate } from 'utils/formatters'
import { ZodIssue } from 'zod'
import { personalDataSchema } from '../schemas/personalDataSchema'

interface Expertise {
  id: number
  name: string
  rqe: string
}

interface FetchSpecialtiesResponse {
  expertises: Expertise[]
}

const usePersonalData = () => {
  const { signedUser, refetchSignedUser } = useSignedUser()
  const [name, setName] = useState(signedUser.name)
  const [nationality, setNationality] = useState('')
  const [preferredName, setPreferredName] = useState('')
  const [genderIdentity, setGenderIdentity] = useState('')
  const [cpf, setCpf] = useState(formatCpf(signedUser.cpf))
  const [rg, setRg] = useState('')
  const [rne, setRne] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [telephone, setTelephone] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [email, setEmail] = useState('')
  const [specialties, setSpecialties] = useState<string[]>([])
  const [specialtiesRQE, setSpecialtiesRQE] = useState<string[]>([])
  const [hasPrescribedBefore, setHasPrescribedBefore] = useState('')
  const [errors, setErrors] = useState<ZodIssue[]>()
  const [specialtiesList, setSpecialtiesList] = useState<SelectOption[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setNationality(signedUser?.nationality ?? '')
    setPreferredName(signedUser?.preferred_name ?? '')
    setGenderIdentity(signedUser?.gender_identity ?? 'Nenhuma das opções listadas')
    setRg(signedUser?.rg ?? '')
    setRne(signedUser?.rne ?? '')
    setBirthdate(formatBirthdayByApi(signedUser?.birthday ?? ''))
    setTelephone(formatPhone(signedUser?.phone ?? ''))
    setCellphone(formatPhone(signedUser?.cellphone ?? ''))
    setEmail(signedUser?.email ?? '')
    setName(signedUser?.name ?? '')
    setCpf(formatCpf(signedUser?.cpf ?? ''))
    setSpecialties(signedUser?.expertises.map((expertise) => String(expertise.id)))
    setHasPrescribedBefore(signedUser.prescriber?.already_prescribed ?? '4')
  }, [signedUser])

  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await api.get<FetchSpecialtiesResponse>('/expertises/all')
        setSpecialtiesList(
          response.data.expertises.map((expertise) => ({
            value: String(expertise.id),
            label: expertise.name,
            rqe: expertise.rqe
          }))
        )
      } catch (err) {
        console.error('Houve algum erro ao buscar as especialidades. Tente novamente mais tarde.')
      }
    }
    fetchSpecialties()
  }, [])

  const removeFieldFromErrors = useCallback((fieldName: string) => {
    setErrors((prevErrors) => prevErrors?.filter((error) => !error.path.includes(fieldName)))
  }, [])

  const setFunctions = {
    name: setName,
    nationality: setNationality,
    genderIdentity: setGenderIdentity,
    cpf: setCpf,
    rg: setRg,
    rne: setRne,
    birthdate: setBirthdate,
    cellphone: setCellphone,
    specialties: setSpecialties,
    hasPrescribedBefore: setHasPrescribedBefore
  }

  const changeFieldValue = useCallback(
    (fieldName: string, newValue: string | string[] | boolean) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const setFunction = setFunctions[fieldName]
      setFunction(newValue)
      if (errors) {
        removeFieldFromErrors(fieldName)
      }
    },
    [errors]
  )

  const changeSpecialties = useCallback(
    (newSpecialties: SelectOption[]) => {
      setSpecialties(newSpecialties.map((specialties) => specialties.value))
      setSpecialtiesRQE(newSpecialties.map((specialties) => specialties.rqe))
      if (errors) {
        removeFieldFromErrors('specialties')
      }
    },
    [errors]
  )

  const onRegistrationFormSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const requiredFieldsValidation = await personalDataSchema.safeParseAsync({
      registrationType: signedUser.registration_type,
      typeOfBoard: signedUser.prescriber?.council ?? '',
      specialties,
      birthdate,
      rg,
      rne,
      genderIdentity,
      name,
      nationality,
      cpf,
      cellphone,
      hasPrescribedBefore
    })

    if (!requiredFieldsValidation.success) {
      setErrors(requiredFieldsValidation.error.errors)
      return
    }
    setErrors(undefined)

    setLoading(true)
    try {
      await api.put(`/my-account/information`, {
        nationality,
        preferred_name: preferredName,
        rg,
        rne,
        gender_identity: genderIdentity,
        phone: clearMask(telephone),
        whatsapp: clearMask(cellphone),
        expertises: specialties.map((specialty) => ({ id: Number(specialty) })),
        already_prescribed: hasPrescribedBefore
      })
      toast.success('Cadastro atualizado com sucesso!')
      refetchSignedUser()
    } catch (err: any) {
      toast.error('Houve um erro ao atualizar o cadastro. Tente novamente mais tarde.')
    }
    setLoading(false)
  }

  return {
    name,
    nationality,
    preferredName,
    setPreferredName,
    genderIdentity,
    cpf,
    rg,
    rne,
    birthdate,
    telephone,
    setTelephone,
    cellphone,
    email,
    specialties,
    hasPrescribedBefore,
    changeFieldValue,
    changeSpecialties,
    errors,
    specialtiesList,
    onRegistrationFormSubmit,
    signedUser,
    loading
  }
}

export default usePersonalData
