import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { toast } from 'react-toastify'
import api from 'services/api'
import { temporaryTheme } from 'styles/theme'
import { NewFormatPrice } from 'utils/formatters'
import * as Styles from './styles'

export interface ProductGridListProps {
  product: any
  importProduct?: any
  handleFavorite?: () => void
}

export default function ProductGridList({
  product,
  handleFavorite = () => null,
  importProduct = () => null
}: ProductGridListProps) {
  const handleFavoriteCard = () => {
    handleFavorite()
    try {
      api.post(`/product/favorite/${product?.id}`)
    } catch (e) {
      toast.error('Erro ao favoritar')
      handleFavorite()
    }

    const msg = product?.isFavorite ? 'Produto removido dos favoritos' : 'Produto adicionado aos favoritos'
    toast.success(msg)
  }

  const validateText = (value: any, format?: string) => {
    if (value) {
      return `${value} ${format}`
    }
    return '-'
  }

  return (
    <Styles.Wrapper>
      <Styles.ImageBox>
        <Styles.FavoriteBox>
          <Styles.FavoriteIcon onClick={handleFavoriteCard}>
            {product?.isFavorite ? (
              <AiFillHeart color={temporaryTheme.colors.red} />
            ) : (
              <AiOutlineHeart color="#858585" />
            )}
          </Styles.FavoriteIcon>
        </Styles.FavoriteBox>
        <Styles.ImageBox>
          <Styles.ImageProduct src={product?.image} />
        </Styles.ImageBox>
      </Styles.ImageBox>

      <Styles.TitleBox>
        <Styles.TitleProduct>{product?.name} </Styles.TitleProduct>
        <Styles.Description>
          {product?.supplier_id === 1 && 'importado - entrega em até 15 dias úteis'}
          {product?.supplier_id === 18 && 'farmácia - entrega em 72h'}
        </Styles.Description>
      </Styles.TitleBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>Forma</Styles.InfoTitle>
        <Styles.InfoValue>{product?.shape?.name}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>Volume</Styles.InfoTitle>
        <Styles.InfoValue>
          {product?.medicalData?.volume}
          {product?.medicalData?.unit_of_measurement}
        </Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>CBD</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.cbd, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>Delta 9</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.delta9_thcv, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>CBG</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.cbg, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>Delta 8</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.delta8, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>CBN</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.cbn, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>THCV</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.delta8_thcv, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>CBDA</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.cbda, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.InfoBox>
        <Styles.InfoTitle>THCA</Styles.InfoTitle>
        <Styles.InfoValue>{validateText(product?.medicalData?.cbda, 'mg/UNI')}</Styles.InfoValue>
      </Styles.InfoBox>

      <Styles.PriceBox>
        <Styles.InfoTitle>Preço</Styles.InfoTitle>
        {/* <Styles.PromotionalPrice>R$ 50.25 </Styles.PromotionalPrice> */}
        <Styles.Price>R$ {NewFormatPrice(product?.real_price)}</Styles.Price>
      </Styles.PriceBox>

      <Styles.ActionBox>
        <Styles.ButtonProduct onClick={importProduct}>Importar</Styles.ButtonProduct>
      </Styles.ActionBox>
    </Styles.Wrapper>
  )
}
