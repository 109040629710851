import { Badge, Button, Dialog, ScrollArea, Typography } from '@cannect/new-components/atoms'
import { Product } from '@cannect/new-components/molecules'
import { Skeletons } from '@cannect/new-components/organisms'
import { PropsWithChildren } from 'react'
import { useProductDetailsDialog } from './useProductDetailsDialog'

export const ProductDetailsDialogUI = ({
  productDetails,

  children
}: PropsWithChildren<ReturnType<typeof useProductDetailsDialog>>) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content className="h-full py-10 md:max-h-[60dvh]">
        {!productDetails && <Skeletons.SkeletonProductItem />}
        {productDetails && (
          <ScrollArea>
            <Product.Root>
              <Product.Header>
                <Product.HeaderImage
                  src={productDetails?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
                  alt={productDetails?.name}
                  isNationalProduct={productDetails?.supplier?.id === 18}
                />
                <Product.HeaderInfo
                  name={productDetails?.name}
                  volume={productDetails?.medical_data?.volume}
                  unitOfMeasurement={productDetails?.medical_data?.unit_of_measurement}
                  brandName={productDetails?.brand?.name}
                />
                <Product.HeaderBadges>
                  {productDetails?.class?.name && (
                    <Badge variant="neutral" className="text-xxs">
                      {productDetails?.class?.name}
                    </Badge>
                  )}
                  {productDetails?.flavor && (
                    <Badge className="bg-flavors-default-100 text-xxs text-flavors-default-900">
                      {productDetails?.flavor || 'Natural'}
                    </Badge>
                  )}
                </Product.HeaderBadges>
              </Product.Header>

              <Typography.Text type="paragraphTwo">{productDetails?.details}</Typography.Text>

              <div>
                <Typography.Text weight="semibold" className="!mb-2 mt-2">
                  Ficha técnica
                </Typography.Text>
                <div className="flex flex-col gap-4">
                  {productDetails?.general_informations &&
                    productDetails?.general_informations.map(({ title, description }) => (
                      <div key={`${title}-${description}`}>
                        <Typography.Text type="paragraphTwo" weight="semibold">
                          {title}
                        </Typography.Text>
                        <Typography.Text type="paragraphTwo">{description}</Typography.Text>
                      </div>
                    ))}
                  {productDetails?.coa_url && (
                    <div>
                      <Typography.Text type="paragraphTwo" weight="semibold">
                        COA:
                      </Typography.Text>
                      <Button
                        unstyled
                        onClick={() => window.open(productDetails?.coa_url, '_blank')}
                        className="font-normal text-primary-700">
                        Fazer download do PDF
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Product.Root>
          </ScrollArea>
        )}
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button size="lg" className="mx-2 font-medium sm:mx-0">
              Fechar
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
