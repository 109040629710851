import { formatInTimeZone } from 'date-fns-tz'
import { TFlavor, TProductCuratorShip, TProductDetailItem, TProductMedicalData } from './types'

export const findRecommendedFlavor = (flavors: TFlavor[], currentFlavor?: string | null): string | undefined => {
  if (flavors && flavors.length > 0) {
    const maxBalance = Math.max(...flavors.map((flavor) => flavor?.balance || 0))
    const highBalanceFlavors = flavors.filter((flavor) => flavor.balance === maxBalance)

    if (highBalanceFlavors && highBalanceFlavors?.length === 1) {
      return highBalanceFlavors[0].flavor
    }
    const productFlavorMatch = highBalanceFlavors.find((flavor) => flavor?.flavor === currentFlavor)
    return productFlavorMatch ? productFlavorMatch.flavor : highBalanceFlavors[0].flavor
  }

  return undefined
}

export const findValidPromotion = (product: TProductDetailItem) => {
  if (!product.promotion) return undefined

  const now = formatInTimeZone(new Date(), 'America/Sao_Paulo', "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")

  const productPrice = Number(product.real_price || product.price)
  const parsedRegularPrice = Number(product.promotion.regular_price || productPrice) || undefined
  const parsedPromotionalPrice = Number(product.promotion.promotional_price || productPrice) || undefined

  if (product?.promotion?.promotion_info) {
    const startDate = product.promotion.promotion_info.start_date || ''
    const endDate = product.promotion.promotion_info.end_date || ''

    if (now > startDate && now < endDate) {
      return {
        valid_promotion: {
          regular_price: parsedRegularPrice,
          promotional_price: parsedPromotionalPrice
        }
      }
    }
  } else if (product?.promotion?.status === 'active') {
    return {
      valid_promotion: {
        regular_price: parsedRegularPrice,
        promotional_price: parsedPromotionalPrice
      }
    }
  }

  return undefined
}

const formatValue = (value?: string | number, unit?: string): string | undefined => {
  if (!value) return undefined
  return unit ? `${value} ${unit.toUpperCase()}` : value.toString()
}

type TSerializedGeneralInformations = {
  title: string
  description?: string
  children?: TSerializedGeneralInformations[]
}

export const serializeProductGeneralInformation = (product?: TProductDetailItem & TProductMedicalData) => {
  if (!product) return []

  const generalInformations: TSerializedGeneralInformations[] = [
    { title: 'Forma', description: product?.shape?.name },
    { title: 'Via de administração', description: product?.administration?.name },
    { title: 'Classe', description: product?.class?.name || '-' },
    { title: 'Sub-classe', description: product?.subclass?.name || '-' },
    { title: 'Volume', description: product?.volume?.toString() },
    { title: 'Unidade de medida', description: product?.unit_of_measurement?.toString() },
    { title: 'Princípio Ativo', description: product?.principio_ativo },
    {
      title: 'Extração e diluição',
      description: '',
      children: [
        { title: 'Método de extração', description: product?.curatorship?.extraction_method },
        {
          title: 'Veiculo',
          description: product?.curatorship?.veiculo === 'Não se aplica' ? '-' : product?.curatorship?.veiculo
        }
      ]
    },
    {
      title: 'CBD',
      description: '',
      children: [
        { title: 'CBD', description: formatValue(product?.cbd, 'mg') },
        {
          title: 'CBD concentração',
          description: formatValue(product?.cbd_concentration, `mg/${product?.unit_of_measurement}`)
        }
      ]
    },
    {
      title: 'THC',
      description: '',
      children: [
        { title: 'THC', description: formatValue(product?.thc, 'mg') },
        {
          title: 'THC concentração',
          description: formatValue(product?.thc_concentration, `mg/${product?.unit_of_measurement}`)
        }
      ]
    },
    {
      title: 'Canabinoides menores',
      description: '',
      children: [
        ...(product?.cbc ? [{ title: 'CBC', description: formatValue(product?.cbc, 'mg') }] : []),
        ...(product?.cbc
          ? [
              {
                title: 'CBC concentração',
                description:
                  (product?.cbc_concentration &&
                    formatValue(product?.cbc_concentration, `mg/${product?.unit_of_measurement}`)) ||
                  ''
              }
            ]
          : []),
        ...(product?.cbda ? [{ title: 'CBDA', description: formatValue(product?.cbda, 'mg') }] : []),
        ...(product?.cbda_concentration
          ? [
              {
                title: 'CBDA concentração',
                description: formatValue(product?.cbda_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.cbdv ? [{ title: 'CBDV', description: formatValue(product?.cbdv, 'mg') }] : []),
        ...(product?.cbdv_concentration
          ? [
              {
                title: 'CBDV concentração',
                description: formatValue(product?.cbdv_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.cbg ? [{ title: 'CBG', description: formatValue(product?.cbg, 'mg') }] : []),
        ...(product?.cbg_concentration
          ? [
              {
                title: 'CBG concentração',
                description: formatValue(product?.cbg_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.cbga ? [{ title: 'CBGA', description: formatValue(product?.cbga, 'mg') }] : []),
        ...(product?.cbga_concentration
          ? [
              {
                title: 'CBGA concentração',
                description: formatValue(product?.cbga_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.cbn ? [{ title: 'CBN', description: formatValue(product?.cbn, 'mg') }] : []),
        ...(product?.cbn_concentration
          ? [
              {
                title: 'CBN concentração',
                description: formatValue(product?.cbn_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.delta8 ? [{ title: 'Delta-8', description: formatValue(product?.delta8, 'mg') }] : []),
        ...(product?.delta8_concentration
          ? [
              {
                title: 'Delta-8 concentração',
                description: formatValue(product?.delta8_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.delta8_thcv
          ? [{ title: 'Delta-8 THCV', description: formatValue(product?.delta8_thcv, 'mg') }]
          : []),
        ...(product?.delta8_thcv_concentration
          ? [
              {
                title: 'Delta-8 THCV concentração',
                description: formatValue(product?.delta8_thcv_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.delta9_thcv
          ? [{ title: 'Delta-9 THCV', description: formatValue(product?.delta9_thcv, 'mg') }]
          : []),
        ...(product?.delta9_thcv_concentration
          ? [
              {
                title: 'Delta-9 THCV concentração',
                description: formatValue(product?.delta9_thcv_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : []),
        ...(product?.delta10 ? [{ title: 'Delta-10', description: formatValue(product?.delta10, 'mg') }] : []),
        ...(product?.delta10_concentration
          ? [
              {
                title: 'Delta-10 concentração',
                description: formatValue(product?.delta10_concentration, `mg/${product?.unit_of_measurement}`)
              }
            ]
          : [])
      ]
    }
  ]

  return generalInformations?.filter((item) => item.description || item.children?.length)
}

export const serializeCuratorship = (data?: TProductCuratorShip) => {
  return {
    'Certificados fornecidos': {
      'Grau farmacêutico x nutricional': data?.pharmacy_rank,
      GACP: data?.gacp,
      Armazenamento: data?.armazenamento,
      Transporte: data?.transport,
      'Rastreabilidade da semente': data?.seed_traceability,
      'Estabilidade em zona climática 4B': data?.stability_climate_zone
    },
    CoA: {
      'Avaliação de canabinoides (potência)': data?.cannabinoid_assessment,
      'Avaliação de terpenos': data?.terpene_assessment,
      'Avaliação de solventes residuais': data?.residual_solvent_assessment,
      'Avaliação de pesticidas': data?.pesticide_assessment,
      'Avaliação de metais pesados': data?.heavy_metal_assessment,
      'Avaliação microbiológica': data?.microbiological_assessment,
      'Avaliação de micotoxinas': data?.mycotoxin_assessment,
      'Avaliação de atividade de água': data?.water_activity_assessment,
      'Avaliação de matéria estranha': data?.foreign_matter_assessment,
      'Avaliação de carga contaminante': data?.contaminant_load_assessment
    },
    'Embalagem/rótulo': {
      'Apresentação da formulação': data?.formulation_presentation,
      'Indicação do espectro': data?.spectrum_indication,
      'Indicação do lote': data?.batch_indication,
      'Indicação da validade': data?.validity_indication,
      'QR code para CoA': data?.qr_code_coa
    },
    Frasco: {
      'Tipo de frasco': data?.bottle_type === 'Não se aplica' ? '-' : data?.bottle_type,
      'Tipo de vidro': data?.glass_type,
      'Trava de segurança': data?.safety_lock,
      'Gradação da Pipeta (marcação)': data?.pipette_graduation === 'Não se aplica' ? '-' : data?.pipette_graduation,
    },
    Variabilidade: {
      'Variabilidade entre lotes': data?.batch_variability,
      'Variabilidade entre CoA e embalagem': data?.coa_packaging_variability
    },
    Adicionais: {
      Vegano: data?.vegan,
      Orgânico: data?.organic,
      Kosher: data?.kosher,
      'Sem açúcar': data?.sugar_free,
      'Sem flavorizante': data?.flavorless
    }
  }
}
